import axios from 'axios';
import FormRow from 'components/FormRow/FormRow';
import IntercomScript from 'components/IntercomScript/IntercomScript';
import { useAppSettings } from 'context/appsettingsContext';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './VerifyAccount.scss';

const VALUE_LENGTH = 6;
const RE_DIGIT = new RegExp(/^\d+$/);

function otpArrayFromString(initialOtp: string) {
  const valueArray = initialOtp.split('');
  const items: Array<string> = [];

  for (let i = 0; i < VALUE_LENGTH; i++) {
    const char = valueArray[i];

    RE_DIGIT.test(char) ? items.push(char) : items.push('');
  }

  return items;
}

export default function VerifyAccount() {
  const settings = useAppSettings();
  const navigate = useNavigate();

  const [otp, setOtp] = useState('');
  const [otpErrors, setOtpErrors] = useState<any | null>([]);
  const [isValidatingOtp, setIsValidatingOtp] = useState(false);
  const [isResendingOtp, setIsResendingOtp] = useState(false);
  const [userPhoneNumberInSession, setUserPhoneNumberInSession] = useState({
    PhoneNumber: '',
    PhoneCode: '',
  });

  const valueItems = useMemo(() => {
    return otpArrayFromString(otp);
  }, [otp]);

  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    setOtpErrors(null);
    const target = e.target;
    let targetValue = target.value;
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const newValue =
      otp.substring(0, idx) + targetValue + otp.substring(idx + 1);

    setOtp(newValue);

    if (!isTargetValueDigit) {
      return;
    }

    const nextElementSibling =
      target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const targetValue = target.value;

    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || target.value !== '') {
      return;
    }

    const previousElementSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    target.setSelectionRange(0, target.value.length);
  };

  useEffect(() => {
    document.title = 'Verify Account | Titan Secure';

    const sessionData = sessionStorage.getItem('userPhoneNumber');
    if (sessionData) {
      setUserPhoneNumberInSession(JSON.parse(sessionData));
    }
  }, []);

  useEffect(() => {
    if (otp.length === VALUE_LENGTH && !/\s/.test(otp)) {
      const handleSubmit = async () => {
        setIsValidatingOtp(true);
        const VALIDATE_OTP_URL = `${settings.baseURL}/Auth/ConfirmPhone`;

        try {
          const response = await axios.post(VALIDATE_OTP_URL, {
            PhoneCode: userPhoneNumberInSession.PhoneCode,
            PhoneNumber: userPhoneNumberInSession.PhoneNumber,
            PhoneConfirmationToken: otp,
          });

          if (response.status === 200) {
            sessionStorage.clear();
            navigate('/success');
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setOtpErrors(error.response?.data.errors);
          }
        }

        setIsValidatingOtp(false);
      };

      handleSubmit();
    }
  }, [navigate, otp, settings.baseURL, userPhoneNumberInSession]);

  async function handleResendOtp(event: { preventDefault: () => void }) {
    event.preventDefault();
    const RESEND_OTP_URL = `${settings.baseURL}/Auth/ResendPhoneConfirmation`;

    setIsResendingOtp(true);

    try {
      await axios.post(RESEND_OTP_URL, {
        phoneCode: userPhoneNumberInSession.PhoneCode,
        phoneNumber: userPhoneNumberInSession.PhoneNumber,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setOtpErrors(error.response?.data.errors);
      }
    }

    setIsResendingOtp(false);
  }

  function renderErrors() {
    let errorArray: Array<string> = [];

    (Object.keys(otpErrors) as (keyof typeof otpErrors)[]).forEach((key) => {
      errorArray.push(otpErrors[key]);
    });

    return errorArray.map((err) => (
      <small
        role='alert'
        aria-label='otp error'
        className='form-field__message form-field__message--error'
        key={err}
      >
        {err}
      </small>
    ));
  }

  return (
    <>
      <div className='fixed-layout fixed-layout--text-center verify'>
        <div className='fixed-layout__main'>
          <div className='narrow-container'>
            <h2>Confirm your cellphone number</h2>
            <p>
              For extra security, please verify your cellphone number by
              entering the OTP we sent you.
            </p>
            <form>
              <FormRow>
                <div className='otp-group'>
                  {valueItems.map((digit, idx) => (
                    <input
                      key={idx}
                      type='text'
                      inputMode='numeric'
                      autoComplete='one-time-code'
                      pattern='\d{1}'
                      maxLength={VALUE_LENGTH}
                      className='otp-input'
                      value={digit}
                      onChange={(e) => inputOnChange(e, idx)}
                      onKeyDown={inputOnKeyDown}
                      onFocus={inputOnFocus}
                      disabled={isValidatingOtp}
                      aria-label='otp input'
                    />
                  ))}
                </div>
                {otpErrors && renderErrors()}
              </FormRow>
            </form>
            <p>Haven’t received a message yet?</p>

            <a
              href='/'
              onClick={handleResendOtp}
              className={isResendingOtp ? 'link-disabled' : ''}
            >
              <strong>{isResendingOtp ? 'Submitting' : 'Resend OTP'}</strong>
            </a>
          </div>
        </div>
      </div>
      <IntercomScript />
    </>
  );
}
