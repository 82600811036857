import { checklistPassed } from 'functions/passwordUtils';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { SignUpStepFormProps } from 'types/signUpForm';
import Button from 'components/Button/Button';
import FormRow from 'components/FormRow/FormRow';
import PasswordChecklist from 'components/PasswordChecklist/PasswordChecklist';
import StepTitle from '../StepTitle/StepTitle';

export default function StepPassword({
  formData,
  handleOnChange,
  handleStepChange,
}: SignUpStepFormProps) {
  return (
    <div className='fixed-layout'>
      <div className='fixed-layout__main'>
        <div className='narrow-container'>
          <StepTitle
            heading='Create a password'
            text='Create a safe password for your Titan&nbsp;Secure account'
          />
          <FormRow>
            <PasswordInput
              id='Password'
              label='Password'
              onChange={handleOnChange}
              value={formData.Password}
            />
          </FormRow>
          <FormRow>
            <PasswordChecklist password={formData.Password} />
          </FormRow>
        </div>
      </div>
      <div className='fixed-layout__footer'>
        <div className='narrow-container'>
          <Button
            label='Next'
            fullWidth
            onClick={() => handleStepChange('details')}
            disabled={!checklistPassed(formData.Password)}
          />
        </div>
      </div>
    </div>
  );
}
