import { Link } from 'react-router-dom';
import Button from 'components/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ClientCard from 'components/ClientCard/ClientCard';
import ClientList from 'components/ClientList/ClientList';
import useGetUsers from 'functions/hooks/useGetUsers';
import FilterNavigation from 'components/FilterNavigation/FilterNavigation';
import { agentOnboaringFilters } from 'pages/Agent/navigation';
import { defaultSortOptions } from 'pages/Agent/menuLinks';
import { useState } from 'react';

export default function OutstandingDetails() {
  const [ searchTextLowerCase, setSearchTextLowerCase] = useState('');
  const { users, loading, sortUsers } = useGetUsers(
    "Roles/Any(r:r eq 'Account-Holder') and totalNumberOfVehiclesWithDevice eq 0 and totalNumberOfVehiclesWithoutDevice eq 0"
  );

  return (
    <>
      <FilterNavigation
        filters={agentOnboaringFilters}
        onSortChange={sortUsers}
        sortOptions={defaultSortOptions}
        onSearchChange={searchText => setSearchTextLowerCase(searchText?.toLocaleLowerCase()?.trim())}
      />
      <ClientList>
        {loading && (
          <div className='client-list__loader'>
            <CircularProgress />
          </div>
        )}

        {!loading && users.length !== 0 && (
          <>
            {users
            .filter((user) => {
              return !searchTextLowerCase ||
              user.firstName?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1 ||
              user.lastName?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1
            })
            .map((client) => (
              <ClientCard
                key={client.id}
                expandable={false}
                clientData={{
                  active: false,
                  email: client.email,
                  firstName: client.firstName,
                  id: client.id,
                  idNumber: client.idNumber,
                  lastName: client.lastName,
                }}
                actionButtons={
                  <Link to={`/agent/onboarding/client/${client.id}/add-driver`}>
                    <Button label='Schedule' small />
                  </Link>
                }
              />
            ))}
          </>
        )}
      </ClientList>
    </>
  );
}
