import { Link } from 'react-router-dom';

import { ReactComponent as HistoryIcon } from 'assets/icons/clock-icon.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import Modal from 'components/Modal/Modal';
import { ClientInterface } from 'functions/hooks/useGetUsers';
import EditClientForm from 'pages/Agent/Onboarding/Forms/EditClientForm';
import EditDriverForm from 'pages/Agent/Onboarding/Forms/EditDriverForm';
import { DriverInterface } from 'types/DriverInterface';
import { VehicleInterface } from 'types/VehicleInterface';

import './InfoCard.scss';

export interface InfoCardProps {
  /** Info card client details */
  clientDetails?: ClientInterface;
  /** Info card driver details */
  driverDetails?: DriverInterface;
  /** Info card car details */
  vehicleDetails?: VehicleInterface;
  /** Info card title */
  title?: string;
  /** Info card subtitle */
  subtitle?: string;
  /** Info card single field to go with subtitle */
  field?: string;
}

function renderField(
  label: string,
  value: string | number,
  fullSize?: boolean
): React.JSX.Element {
  let linkPrefix = '';
  if (label === 'Email address') {
    linkPrefix = 'mailto:';
  } else if (label === 'Cellphone number') {
    linkPrefix = 'tel:';
  }

  return (
    <div
      className={`info-card__field ${fullSize ? 'info-card__field-full' : ''}`}
    >
      {linkPrefix ? (
        <a
          href={`${linkPrefix}${value}`}
          className='info-card__link'
          aria-label={`info card ${label}`}
        >
          {value}
        </a>
      ) : (
        <div>{value}</div>
      )}
      <div className='info-card__field-title'>{label}</div>
    </div>
  );
}

/** UI component containing user information */
export default function InfoCard({
  title,
  subtitle,
  field,
  clientDetails,
  driverDetails,
  vehicleDetails,
}: InfoCardProps) {
  return (
    <div>
      <div className='flex align-items-center justify-content-space-between'>
        {title && (
          <div className='info-card__title' aria-label='Info card title'>
            {title}
          </div>
        )}

        {clientDetails && (
          <Modal
            title='Account holder details'
            trigger={
              <Link
                to={'#'}
                aria-label='client edit icon'
                className='info-card__icon'
              >
                <EditIcon />
              </Link>
            }
          >
            <EditClientForm userDetails={clientDetails} />
          </Modal>
        )}

        {driverDetails && (
          <Modal
            title='Driver details'
            trigger={
              <Link
                to={'#'}
                className='info-card__icon'
                aria-label='driver edit icon'
              >
                <EditIcon />
              </Link>
            }
          >
            <EditDriverForm userDetails={driverDetails} />
          </Modal>
        )}
        {vehicleDetails && (
          <div className='info-card__button-wrapper'>
            <Link
              aria-label='view history'
              to={`/agent/clients/${vehicleDetails.userId}/vehicle/${vehicleDetails.id}/history`}
              className='info-card__icon'
            >
              <HistoryIcon />
            </Link>
            {/* <Modal
              title='Delete installation request'
              trigger={
                <Link to={'#'} className='info-card__icon'>
                  <DeleteIcon />
                </Link>
              }
            >
              Please confirm that you are removing this vehicle from the
              customer's account. This action will permanently remove the
              vehicle and its associated information from our system. Once
              confirmed, the removal cannot be undone. Please proceed with
              caution.
            </Modal> */}
          </div>
        )}
      </div>

      <div className='info-card'>
        {subtitle && (
          <div>
            <div
              className='info-card__subtitle'
              aria-label='Info card subtitle'
            >
              {subtitle}
            </div>

            <div>{field}</div>
          </div>
        )}

        {clientDetails && (
          <div className='info-card__fields'>
            {renderField('Email address', clientDetails.email)}
            {renderField(
              'Cellphone number',
              '+' + clientDetails.phoneCode + clientDetails.phoneNumber
            )}
            {renderField('ID number', clientDetails.idNumber)}
          </div>
        )}

        {driverDetails && (
          <div className='info-card__fields'>
            {renderField('Email address', driverDetails.email)}
            {renderField(
              'Cellphone number',
              '+' + driverDetails.phoneCode + driverDetails.phoneNumber
            )}
            {renderField('ID number', driverDetails.idNumber)}
          </div>
        )}

        {vehicleDetails && (
          <div className='info-card__fields'>
            {renderField(
              'Vehicle',
              vehicleDetails.make + '' + vehicleDetails.model,
              true
            )}
            {renderField('Year', vehicleDetails.registrationYear)}
            {renderField('Colour', vehicleDetails.vehicleColour)}
            {vehicleDetails.vinNumber &&
              renderField('VIN number', vehicleDetails.vinNumber)}
            {vehicleDetails.engineNumber &&
              renderField('Engine number', vehicleDetails.engineNumber)}
          </div>
        )}
      </div>
    </div>
  );
}
