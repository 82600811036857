import './CreatePassword.scss';
import { checklistPassed } from 'functions/passwordUtils';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import FormRow from 'components/FormRow/FormRow';
import NumberOne from 'assets/icons/number-one.svg';
import PasswordChecklist from 'components/PasswordChecklist/PasswordChecklist';
import { useAppSettings } from 'context/appsettingsContext';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { FormLayout } from 'layouts/FormLayout/FormLayout';

export default function CreatePassword() {
  const [password, setPassword] = useState('');
  const settings = useAppSettings();
  const [error, setError] = useState('');
  const [userToken, setUserToken] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);


  useEffect(() => {
    const handleResize = () => {
        setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
        window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.title = 'Create Password | Titan Secure';
    const resetToken = searchParams.get('token');
    setUserToken(resetToken);
  }, [searchParams]);

  async function handleCompletePasswordReset(URL: string, PAYLOAD: object) {
    try {
      const response = await axios.post(URL, PAYLOAD);

      if (response?.status === 200) {
        const isResetComplete = await isCompletePasswordReset();
        if (isResetComplete) {
          setIsSuccess(true);
        }
      } else {
        setError('An error occurred while creating your password');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.message);
      }
    }
  }

  const isCompletePasswordReset = async () => {
    const passwordResetUrl = `${settings.baseURL}/Auth/CompletePasswordReset`;
    const payload = {
      passwordResetToken: searchParams.get('token'),
      password: password,
      passwordConfirmation: password,
    };
    try {
      const response = await axios.post(passwordResetUrl, payload);

      if (response?.status === 200) {
        return true;
      } else return false;

    } catch (error) {
      if (axios.isAxiosError(error)) {
        setError(error.message);
        return false;
      }
    }
  }

  function handleSubmit() {
    const passwordResetUrl = `${settings.baseURL}/My/CreatePassword`;
    const payload = {
      password: password,
      passwordResetToken: userToken,
    };

    handleCompletePasswordReset(passwordResetUrl, payload);
  }

  return (
    <FormLayout>
      <FormLayout.Main isNotNarrow={isMobileView}>
        <div className='fixed-layout create-password'>
          <div className='fixed-layout__main'>
            <div className='narrow-container narrow-container--center-text'>
              <img src={NumberOne} alt='Number 1' />
                <h2>
                  SA’s 1st <br /> Multi-layered <br /> Vehicle Safety Solution
                </h2>

                {isSuccess ? (
                  <div style = {{
                    marginTop: '20vh',
                  }}>
                    <h2 style = {{ color: "green" }}>Password created successfully!</h2>
                    <p>You can now log in on the mobile app using your new password.</p>
                  </div>
                ) : (
                  <form>
                    <FormRow>
                      <PasswordInput
                        id='password'
                        label='Password'
                        onChange={(event) => setPassword(event.target.value)}
                        value={password}
                      />
                    </FormRow>
                    {error.length > 0 && (
                      <small className='form-field__message form-field__message--error'>
                        {error}
                      </small>
                    )}
                    <FormRow>
                      <PasswordChecklist password={password} />
                    </FormRow>
                  </form>
                )}
            </div>
          </div>
          {!isSuccess && (
            <div className='fixed-layout__footer'>
              <div className='narrow-container'>
                <Button
                  onClick={handleSubmit}
                  disabled={!checklistPassed(password)}
                  fullWidth
                  label='Create password'
                />
              </div>
            </div>
          )}
        </div>
      </FormLayout.Main>
    </FormLayout>
  );
}
