import Button from 'components/Button/Button';
import { FormInput } from 'components/FormInput/FormInput';
import FormRow from 'components/FormRow/FormRow';
import InfoBox from 'components/InfoBox/InfoBox';
import RadioButton from 'components/RadioButton/RadioButton';
import RadioButtonGroup from 'components/RadioButtonGroup/RadioButtonGroup';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { FormErrors } from 'types/signUpForm';
import { ClientDetailType } from './AddClientForm';
import { DriverOptionType } from './AddDriverAndVehicle';
import { useNavigate } from 'react-router-dom';

export interface AddDriverFormProps {
  formData: ClientDetailType;
  formErrors: FormErrors;
  handleValidation: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleFormChange: (step: string) => void;
  handleDriverOptions: (option: DriverOptionType) => void;
  driverOptions: string;
}



export default function AddDriverForm({
  formData,
  formErrors,
  handleValidation,
  handleChange,
  driverOptions,
  handleDriverOptions,
  handleFormChange,
}: AddDriverFormProps) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };
  
  return (
    <FormLayout>
      <FormLayout.Header>
        <FormLayout.HeaderBack onClose={handleGoBack}/>
        <p aria-label='driver details title'>Driver details</p>
      </FormLayout.Header>
      <FormLayout.Main>
        <InfoBox
          title='Who will be driving the vehicle?'
          helperText="Request the necessary driver details from the customer,
            such as their driver's license number and expiration date,
            to ensure compliance with our security protocols.
            Please guide the customer through providing this information securely."
        />
        <FormRow>
          <RadioButtonGroup>
            <RadioButton
              label='Same as account&nbsp;holder'
              id='current-account'
              name='driver'
              selectedState={driverOptions}
              value='current-account'
              handleChange={() => handleDriverOptions('current-account')}
            />
            <RadioButton
              label='Someone else'
              id='new-driver'
              name='driver'
              selectedState={driverOptions}
              value='new-driver'
              handleChange={() => handleDriverOptions('new-driver')}
            />
          </RadioButtonGroup>
        </FormRow>
        {driverOptions === 'new-driver' && (
          <form>
            <FormRow>
              <FormInput
                id='FirstName'
                label='Name'
                value={formData.FirstName}
                onChange={handleChange}
                errorMessage={formErrors.FirstName}
                onBlur={handleValidation}
              />
            </FormRow>
            <FormRow>
              <FormInput
                id='LastName'
                label='Surname'
                value={formData.LastName}
                onChange={handleChange}
                errorMessage={formErrors.LastName}
                onBlur={handleValidation}
              />
            </FormRow>
            <FormRow>
              <FormInput
                id='Email'
                label='Email address'
                value={formData.Email}
                onChange={handleChange}
                errorMessage={formErrors.Email}
                onBlur={handleValidation}
              />
            </FormRow>
            <FormRow>
              <FormInput
                type='tel'
                id='PhoneNumber'
                value={formData.PhoneNumber}
                label='Cellphone number'
                onChange={handleChange}
                helpText='Required format: 0821234567'
                errorMessage={formErrors.PhoneNumber}
                onBlur={handleValidation}
              />
            </FormRow>
            <FormRow>
              <ToggleSwitch
                label='Has a South&nbsp;African ID'
                checked={formData.IdNumberIsSouthAfrican}
                htmlFor='IdNumberIsSouthAfrican'
                onChange={handleChange}
              />
            </FormRow>
            <FormRow>
              <FormInput
                type='text'
                id='IdNumber'
                label={
                  formData.IdNumberIsSouthAfrican
                    ? 'South African ID Number'
                    : 'Passport Number'
                }
                value={formData.IdNumber}
                onChange={handleChange}
                errorMessage={formErrors.IdNumber}
                onBlur={handleValidation}
              />
            </FormRow>
          </form>
        )}
      </FormLayout.Main>
      <FormLayout.Footer>
        {driverOptions === 'current-account' ? (
          <Button
            label='Next'
            fullWidth
            onClick={() => handleFormChange('vehicle-details')}
          />
        ) : (
          <Button
            label='Next'
            onClick={() => handleFormChange('vehicle-details')}
            fullWidth
            disabled={
              !formData.FirstName ||
              !formData.LastName ||
              !formData.Email ||
              !formData.PhoneNumber ||
              !formData.IdNumber
            }
          />
        )}
      </FormLayout.Footer>
    </FormLayout>
  );
}
