import { FormInput } from 'components/FormInput/FormInput';
import { SignUpStepFormProps } from 'types/signUpForm';
import Button from 'components/Button/Button';
import FormRow from 'components/FormRow/FormRow';
import InfoBox from 'components/InfoBox/InfoBox';
import StepTitle from '../StepTitle/StepTitle';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

export default function StepDetails({
  formData,
  formErrors,
  handleInputValidation,
  handleOnChange,
  handleSubmit,
  isSubmitting,
}: SignUpStepFormProps) {
  return (
    <div className='fixed-layout'>
      <div className='fixed-layout__main'>
        <div className='narrow-container'>
          <StepTitle
            heading='Your details'
            text='Please fill in the form below so we are able to contact you.'
          />
          <InfoBox
            helperText='Your security and privacy are our top priorities.
              By&nbsp;providing your contact details and ID number during the
              sign-up process, we ensure a seamless and secure experience for
              you.'
          />
          <FormRow>
            <FormInput
              id='FirstName'
              label='Name'
              value={formData.FirstName}
              onChange={handleOnChange}
              onBlur={handleInputValidation}
              errorMessage={formErrors.FirstName}
            />
          </FormRow>
          <FormRow>
            <FormInput
              id='LastName'
              label='Surname'
              value={formData.LastName}
              onChange={handleOnChange}
              onBlur={handleInputValidation}
              errorMessage={formErrors.LastName}
            />
          </FormRow>
          <FormRow>
            <FormInput
              type='tel'
              id='PhoneNumber'
              label='Cellphone number'
              value={formData.PhoneNumber}
              onChange={handleOnChange}
              onBlur={handleInputValidation}
              errorMessage={formErrors.PhoneNumber}
              helpText='Required format: 0821234567'
            />
          </FormRow>
          <FormRow>
            <ToggleSwitch
              label='I have a South&nbsp;African ID'
              checked={formData.IdNumberIsSouthAfrican}
              htmlFor='IdNumberIsSouthAfrican'
              onChange={handleOnChange}
            />
          </FormRow>
          <FormRow>
            <FormInput
              type='text'
              id='IdNumber'
              label={
                formData.IdNumberIsSouthAfrican
                  ? 'South African ID Number'
                  : 'Passport Number'
              }
              value={formData.IdNumber}
              onChange={handleOnChange}
              onBlur={handleInputValidation}
              errorMessage={formErrors.IdNumber}
            />
          </FormRow>
        </div>
      </div>
      <div className='fixed-layout__footer'>
        <div className='narrow-container'>
          <Button
            label={isSubmitting ? 'Submitting' : 'Get started'}
            fullWidth
            onClick={handleSubmit}
            disabled={
              !formData.FirstName ||
              !formData.LastName ||
              !formData.PhoneNumber ||
              !formData.IdNumber ||
              formErrors?.PhoneNumber?.length !== 0 ||
              isSubmitting
            }
          />
        </div>
      </div>
    </div>
  );
}
