import './ToggleSwitch.scss';

interface ToggleSwitchProps {
  /** The current checked state */
  checked: boolean;
  /** Used for the id & name on the input tag and the htmlFor on the label tag */
  htmlFor: string;
  /** Label text */
  label?: string;
  /** Swop the label and toggle positions */
  labelReversed?: boolean;
  /** onChange to control the checked state  */
  onChange?: (event: any) => void;
}

/** UI component for a toggle switch */
export default function ToggleSwitch({
  checked,
  htmlFor,
  label,
  labelReversed,
  onChange,
}: ToggleSwitchProps) {
  return (
    <div
      className={`
        toggle-switch
        ${labelReversed && label && 'toggle-switch--reversed'}
    `}
    >
      {label && <div className='label'>{label}</div>}
      <label className='toggle-switch__track-container' htmlFor={htmlFor}>
        <input
          type='checkbox'
          checked={checked}
          name={htmlFor}
          id={htmlFor}
          onChange={onChange}
          aria-label={htmlFor}
        />
        <span className='toggle-switch__slider'></span>
      </label>
    </div>
  );
}
