import axios from 'axios';
import Button from 'components/Button/Button';
import FormRow from 'components/FormRow/FormRow';
import PasswordChecklist from 'components/PasswordChecklist/PasswordChecklist';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { checklistPassed } from 'functions/passwordUtils';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function ResetPassword() {
  const { authorisedUser } = useAuth();
  const settings = useAppSettings();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validationError, setValidationError] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tokenValidationError, setTokenValidationError] = useState({
    PasswordResetToken: [],
  });

  function handleValidation() {
    if (newPassword && confirmPassword) {
      if (newPassword !== confirmPassword) {
        setValidationError('Passwords do not match');
      } else {
        setValidationError('');
      }
    }
  }

  async function handleCompletePasswordReset(URL: string, PAYLOAD: object) {
    let authUser: { headers?: Record<string, string> } = {};

    if (searchParams.get('token') === null) {
      authUser.headers = {
        Authorization: `Bearer ${authorisedUser.accessToken}`,
      };
    }

    try {
      const response = await axios.post(URL, PAYLOAD, authUser);

      if (response?.status === 200) {
        navigate('/');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  }

  async function validateTokenThenSubmit() {
    const URL = `${settings.baseURL}/Auth/VerifyPasswordReset`;
    const PAYLOAD = { passwordResetToken: searchParams.get('token') };

    setIsSubmitting(true);

    try {
      const response = await axios.post(URL, PAYLOAD);
      if (response?.status === 200) {
        const passwordResetUrl = `${settings.baseURL}/Auth/CompletePasswordReset`;
        const payload = {
          passwordResetToken: searchParams.get('token'),
          password: newPassword,
          passwordConfirmation: confirmPassword,
        };

        handleCompletePasswordReset(passwordResetUrl, payload);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setTokenValidationError(error.response?.data.errors);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleSubmit() {
    if (searchParams.get('token') !== null) {
      validateTokenThenSubmit();
    } else {
      const passwordResetUrl = `${settings.baseURL}/My/UpdatePassword`;
      const payload = {
        password: newPassword,
        passwordConfirmation: confirmPassword,
      };

      handleCompletePasswordReset(passwordResetUrl, payload);
    }
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>Reset password | Titan Secure</title>
      </Helmet>
      <FormLayout>
        <FormLayout.Header>
          <FormLayout.HeaderBack onClose={handleGoBack} iconType='back' />
          <p>Create a password</p>
        </FormLayout.Header>
        <FormLayout.Main>
          <FormRow>
            <PasswordInput
              id='newPassword'
              label='New password'
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              onBlur={handleValidation}
            />
          </FormRow>
          <FormRow>
            <PasswordChecklist password={newPassword} />
          </FormRow>
          <FormRow>
            <PasswordInput
              id='confirmPassword'
              label='Confirm password'
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              onBlur={handleValidation}
              errorMessage={validationError}
            />
          </FormRow>
          <small className='form-field__message form-field__message--error'>
            {tokenValidationError.PasswordResetToken}
          </small>
        </FormLayout.Main>
        <FormLayout.Footer>
          <Button
            onClick={handleSubmit}
            label={isSubmitting ? 'Submitting' : 'Create password'}
            fullWidth
            disabled={
              !checklistPassed(newPassword) || newPassword !== confirmPassword || isSubmitting
            }
          />
        </FormLayout.Footer>
      </FormLayout>
    </>
  );
}
