import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useState } from 'react';
import Badge, { BadgeTypes } from 'components/Badge/Badge';
import DotMenuButton from 'components/Button/DotMenuButton';
import DropdownMenu from 'components/Menu/Menu';
import VehicleCard from 'components/VehicleCard/VehicleCard';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { VehicleInterface } from 'types/VehicleInterface';
import './ClientCard.scss';

interface ClientCardProps {
  /** Any data related to the client */
  clientData: {
    active?: boolean;
    email?: string;
    firstName: string;
    id: number;
    idNumber?: string;
    lastName: string;
    makeVariant?: string;
    phoneCode?: string;
    phoneNumber?: string;
    registrationNumber?: string;
    state?: BadgeTypes;
    totalNumberOfVehicles?: number;
    vehicles?: VehicleInterface[];
    tamperActive?: boolean;
  };
  /** State of the device eg. locked, unlocked, etc */
  state?: BadgeTypes;
  /** Pass in HTML or Components as props for actions */
  actionButtons?: React.ReactNode;
  /** Can the card expand when clicking on the title */
  expandable?: boolean;
  /** Boolean flag to fetch vehicles that are active or not. Used in the filter params on getVehicles */
  getActiveDevices?: boolean;
}

/** A UI component used to display client data with expand/collapse functionality */
export default function ClientCard({
  clientData,
  actionButtons,
  expandable = true,
  getActiveDevices = true,
}: ClientCardProps) {
  const {
    id,
    state,
    firstName,
    lastName,
    registrationNumber,
    makeVariant,
    tamperActive,
  } = clientData;

  const settings = useAppSettings();
  const { authorisedUser } = useAuth();
  const [cardExpanded, setCardExpanded] = useState(false);
  const [clientVehicles, setClientVehicles] = useState([]);
  const [loadingClientVehicles, setLoadingClientVehicles] = useState(false);

  function handleCardExpanding() {
    if (expandable) {
      setCardExpanded(!cardExpanded);
    }

    if (!cardExpanded) {
      getVehicles();
    }
  }

  async function getVehicles() {
    if (settings?.baseURL) {
      let URL = `${settings.baseURL}/Vehicles?$filter=userId eq ${clientData.id} and deviceActive eq ${getActiveDevices}`;

      setLoadingClientVehicles(true);

      try {
        const response = await axios.get(URL, {
          headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
        });

        if (response?.status === 200) {
          setClientVehicles(response.data.items);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error);
        }
      } finally {
        setLoadingClientVehicles(false);
      }
    }
  }


  const accountMenu = [
    {
      path: `/agent/onboarding/client/${id}/add-driver`,
      label: 'Add a vehicle',
    },
    { path: `/agent/clients/${id}/account/view`, label: 'View account' },
  ];

  return (
    <li className='client-card' aria-label='client card'>
      <div className='client-card__header'>
        <div
          onClick={handleCardExpanding}
          className='client-card__header-title'
          aria-label='Card title'
          style={{ cursor: expandable ? 'pointer' : 'default' }}
        >
          <div className='client-card__header-name'>
            {firstName} {lastName}
          </div>
          {registrationNumber && (
            <div className='client-card__header-vehicle'>
              <span>{registrationNumber.toLocaleUpperCase()}</span>
              <span>{makeVariant}</span>
            </div>
          )}
        </div>

        {actionButtons}
        {tamperActive && <Badge state={'alert'} />}
        {state && <Badge state={state} />}
        <DropdownMenu links={accountMenu} buttonComponent={<DotMenuButton />} />
      </div>
      <div
        className={`client-card__body ${cardExpanded ? 'client-card__body--expanded' : ''
          }`}
        aria-label='Card body'
        aria-expanded={cardExpanded}
      >
        {loadingClientVehicles && <CircularProgress />}
        <div className='vehicleCardContainer'>
          {!loadingClientVehicles &&
            clientVehicles.map((vehicle: VehicleInterface) => (
              <div className='vehicle' key={vehicle.id}>
                <VehicleCard
                  vehicleId={vehicle.id}
                  deviceId={vehicle.deviceId}
                  deviceSerialNumber={vehicle.deviceSerialNumber}
                  gpsDateTime={vehicle.gpsDateTime}
                  deviceActive={vehicle.deviceActive}
                  drivers={vehicle.drivers}
                  isLocked={vehicle.isLocked}
                  gps={{
                    lat: vehicle.gpsLatitudeDegrees,
                    lng: vehicle.gpsLongitudeDegrees,
                  }}
                  make={vehicle.make}
                  nickname={vehicle.nickname}
                  registration={vehicle.registrationNumber}
                  tamperActive={vehicle.tamperActive}
                />
              </div>
            ))}
        </div>
      </div>
    </li>
  );
}
