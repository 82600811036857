import ClientList from 'components/ClientList/ClientList';
import ClientCard from 'components/ClientCard/ClientCard';
import useGetUsers from 'functions/hooks/useGetUsers';
import CircularProgress from '@mui/material/CircularProgress';
import FilterNavigation from 'components/FilterNavigation/FilterNavigation';
import { agentClientsFilters } from 'pages/Agent/navigation';
import { clientSortOptions } from 'pages/Agent/menuLinks';
import { useEffect, useState } from 'react';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

interface AccessTokenPayload {
  exp: number;
}

export default function AllClients() {
  const settings = useAppSettings();
  const { authorisedUser, logout } = useAuth();
  const [ searchTextLowerCase, setSearchTextLowerCase] = useState('');
  const { users, loading, sortUsers } = useGetUsers(
    "Roles/Any(r:r eq 'Account-Holder') and totalNumberOfVehiclesWithDevice gt 0"
  );

  useEffect(() => {
    const checkAccessToken = async () => {
      if (authorisedUser && isValidJWT(authorisedUser.accessToken)) {
        const accessToken = authorisedUser.accessToken;
        const refreshToken = authorisedUser.refreshToken;
        const URL = `${settings.baseURL}/Auth/RefreshTokens`;

        // console.log(authorisedUser.accessToken);
        // console.log(authorisedUser.refreshToken);

        if (refreshToken && accessToken) {
          const decodedAccessToken = jwtDecode<AccessTokenPayload>(accessToken);

          const currentTime = Date.now() / 1000;
          if (decodedAccessToken.exp < currentTime) {
            try {
              const response = await axios.post(URL,
                {
                  accessToken: accessToken,
                  refreshToken: refreshToken,
                }
              );
              localStorage.setItem(
                'authorised-user',
                JSON.stringify({
                  accessToken: response.data.accessToken,
                  refreshToken: authorisedUser.refreshToken,
                  userId: authorisedUser.userId,
                  userRole: authorisedUser.userRole,
                })
              );
            } catch (error) {
              logout();
            }
          }
        }
      }
    };
    checkAccessToken();
  }, []);

  function isValidJWT(token: string): boolean {
    try {
      jwtDecode<AccessTokenPayload>(token);
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <>
      <FilterNavigation
        filters={agentClientsFilters}
        onSortChange={sortUsers}
        sortOptions={clientSortOptions}
        onSearchChange={searchText => setSearchTextLowerCase(searchText?.toLocaleLowerCase()?.trim())}
      />
      <ClientList>
        {loading && (
          <div className='client-list__loader'>
            <CircularProgress />
          </div>
        )}
        {!loading &&
          users
          .filter((user) => {
            return !searchTextLowerCase ||
            user.firstName?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1 ||
            user.lastName?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1
          })
          .map((user) => (
            <ClientCard
              key={user.id}
              clientData={{
                firstName: user.firstName,
                id: user.id,
                lastName: user.lastName,
                tamperActive: user.tamperActive,
              }}
            />
          ))}
      </ClientList>
    </>
  );
}