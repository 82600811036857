import { FormInput } from 'components/FormInput/FormInput';
import { SignUpStepFormProps } from 'types/signUpForm';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import FormRow from 'components/FormRow/FormRow';
import InfoBox from 'components/InfoBox/InfoBox';
import StepTitle from '../StepTitle/StepTitle';

export default function StepEmail({
  formData,
  formErrors,
  handleInputValidation,
  handleOnChange,
  handleStepChange,
}: SignUpStepFormProps) {
  return (
    <div className='fixed-layout'>
      <div className='fixed-layout__main'>
        <div className='narrow-container'>
          <StepTitle
            heading='Enter your email'
            text='Add your email so that we can securely communicate with you regarding your account and vehicle.'
          />
          <InfoBox
            helperText='Your privacy is important to us, and we are committed
              to maintaining the confidentiality and security of your personal
              details throughout your journey with Titan Secure.'
          />
          <FormRow>
            <FormInput
              id='Email'
              label='Email address'
              value={formData.Email}
              onChange={handleOnChange}
              errorMessage={formErrors.Email}
              onBlur={handleInputValidation}
            />
          </FormRow>
          <FormRow>
            <CheckBox
              name='termsAndConditions'
              id='AcceptedTermsAndConditionsAndPolicy'
              checked={formData.AcceptedTermsAndConditionsAndPolicy}
              onChange={handleOnChange}
            >
              <p>
                I confirm that I have read and agree to the{' '}
                <a
                  href='https://uploads-ssl.webflow.com/646a59198e3c5d854d43d7f9/64be3cbb94b951f4481d91f7_Titan%20Website%20Terms%20of%20Use%20v1.pdf'
                  target='_blank'
                  rel='noreferrer'
                >
                  <strong>terms and conditions </strong>
                </a>
                and{' '}
                <a
                  href='https://www.iubenda.com/privacy-policy/71352590'
                  target='_blank'
                  rel='noreferrer'
                >
                  <strong>privacy policy</strong>
                </a>
                .
              </p>
            </CheckBox>
          </FormRow>
          <FormRow>
            <CheckBox
              name='marketingConsent'
              id='AcceptedToAllowInformationToBeUsedToContact'
              checked={formData.AcceptedToAllowInformationToBeUsedToContact}
              label='I confirm that I have read and give consent to receive marketing and sales communications.'
              onChange={handleOnChange}
            />
          </FormRow>
        </div>
      </div>
      <div className='fixed-layout__footer'>
        <div className='narrow-container'>
          <Button
            label='Next'
            fullWidth
            onClick={() => handleStepChange('password')}
            disabled={
              !formData.Email ||
              !formData.AcceptedTermsAndConditionsAndPolicy ||
              formErrors?.Email?.length !== 0
            }
          />
        </div>
      </div>
    </div>
  );
}
