import { FormInput } from 'components/FormInput/FormInput';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { validateInput } from 'functions/validationUtils';
import { trimInputValue } from 'functions/trimInputValue';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from 'components/Button/Button';
import FormRow from 'components/FormRow/FormRow';
import React, { useState } from 'react';

export default function AddCompany() {
  const navigate = useNavigate();
  const settings = useAppSettings();
  const { authorisedUser } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    CompanyName: '',
  });

  const [formErrors, setFormErrors] = useState({
    CompanyName: [],
  });

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    const inputValue = trimInputValue(id, value);
    
    setFormData((prevState) => ({
      ...prevState,
      [id]: inputValue,
    }));
  }

  function handleInputValidation(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setFormErrors((prevState) => ({
      ...prevState,
      [id]: validateInput(id, value, false),
    }));
  }

  async function handleSubmit() {
    const URL = `${settings.baseURL}/Companies`;

    setIsSubmitting(true);

    try {
      const response = await axios.post(URL, {
        Name: formData.CompanyName,
        CompanyUsers: [],
        Vehicles: []
      }, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });
      if (response?.status === 200) {
        navigate(`/agent/companies/${response.data?.id!}/link-user`);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setFormErrors(error.response?.data.errors);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <FormLayout>
      <FormLayout.Header>
        <FormLayout.HeaderClose linkPath='/' />
        <p>Add Company</p>
      </FormLayout.Header>
      <FormLayout.Main>
        <FormRow>
          <FormInput
            id='CompanyName'
            label='Name'
            onChange={handleOnChange}
            value={formData.CompanyName}
            onBlur={handleInputValidation}
            errorMessage={formErrors.CompanyName}
          />
        </FormRow>
      </FormLayout.Main>
      <FormLayout.Footer>
        <Button
          label={isSubmitting ? 'Submitting' : 'Add Company'}
          fullWidth
          onClick={handleSubmit}
          disabled={
            !formData.CompanyName ||
            isSubmitting
          }
        />
      </FormLayout.Footer>
    </FormLayout>
  );
}