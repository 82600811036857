import './PasswordInput.scss';
import { ReactComponent as EyeClosedIcon } from 'assets/icons/eye-closed.svg';
import { ReactComponent as EyeOpenIcon } from 'assets/icons/eye-open.svg';
import { useState } from 'react';

interface PasswordInputProps {
  /** The error message you want to display */
  errorMessage?: string;
  /** Unique id for the input and label elements */
  id: string;
  /** Used for both the label and the placeholder */
  label: string;
  /** The onChange can be used to control the input/value  */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** The controlled value of the input */
  value: string;
  /** The onBlur event handler */
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** Optional help text for the input */
  helpText?: string;
}

/**
 * An general input component used for forms.
 */
export const PasswordInput = ({
  errorMessage,
  id,
  label,
  onChange,
  value,
  onBlur,
  helpText,
}: PasswordInputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className='form-field password-input'>
      <input
        type={showPassword ? 'text' : 'password'}
        id={id}
        name={id}
        placeholder={label}
        onChange={onChange}
        value={value}
        className={`${errorMessage && 'field-error'}`}
        onBlur={onBlur}
        aria-label={label}
      />
      <label htmlFor={id}>{label}</label>

      <small
        aria-label={`${label} ${errorMessage ? 'error' : 'help'}`}
        className={`form-field__message ${
          errorMessage ? 'form-field__message--error' : ''
        }`}
      >
        {errorMessage || helpText}
      </small>
      <div
        className='password-input__icon'
        aria-label='showPassword'
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword ? <EyeClosedIcon /> : <EyeOpenIcon />}
      </div>
    </div>
  );
};
