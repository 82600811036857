import { createContext, useContext, useState } from 'react';
import { useAppSettings } from 'context/appsettingsContext';
import { useLocalStorage } from './useLocalStorage';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

interface Props {
  children: React.ReactNode;
}

interface UserType {
  accessToken: string;
  refreshToken: string;
  userId: number;
  userRole: string;
}

interface AuthContextType {
  errors: string;
  handleLogin: (newState: any) => Promise<void>;
  logout: () => void;
  authorisedUser: UserType;
  submitting: boolean;
}

interface LogInCredentials {
  email: string;
  password: string;
}

const initialValue = {
  authorisedUser: {
    accessToken: '',
    refreshToken: '',
    userId: 0,
    userRole: '',
  },
  handleLogin: () => Promise.resolve(),
  logout: () => {},
  errors: '',
  submitting: false,
};

const AuthContext = createContext<AuthContextType>(initialValue);

export const AuthProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const settings = useAppSettings();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState('');
  const [authorisedUser, setAuthorisedUser] = useLocalStorage(
    'authorised-user',
    null
  );

  async function handleLogin(data: LogInCredentials) {
    setErrors('');
    setSubmitting(true);

    const URL = `${settings.baseURL}/Auth/SignInByEmail`;

    try {
      const response = await axios.post(URL, data);
      if (response?.status === 200) {
        if (response.data.userRole === 'Account-Holder') {
          setErrors('Invalid email and password');
        } else {
          setAuthorisedUser(response.data);
          navigate('/agent');
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setErrors(
          error?.response?.data.message || error?.response?.data.errors?.Email
        );
      }
    }

    setSubmitting(false);
  }

  function logout() {
    setAuthorisedUser(null);
    navigate('/', { replace: true });
  }

  return (
    <AuthContext.Provider
      value={{ authorisedUser, handleLogin, errors, submitting, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
