import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { forwardRef, useImperativeHandle, useState } from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close-x.svg';

import './Modal.scss';

export interface ModalProps {
  title: string;
  styleClass?: string;
  innerHeight?: string;
  trigger: React.ReactNode;
  children?: React.ReactNode;
  onClose?: () => void;
}

const Modal = forwardRef(({
  title,
  styleClass,
  trigger,
  children,
  onClose,
  innerHeight
}: ModalProps, ref) => {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  useImperativeHandle(ref, () => ({
    close() {
      setOpen(false);
    }
  }));

  return (
    <>
      <div className={styleClass} onClick={handleClickOpen}>
        {trigger}
      </div>

      <Dialog
        onClose={handleClose}
        aria-labelledby='modal-title'
        open={open}
        className='modal'
      >
        <DialogTitle
          className='modal__title flex justify-content-space-between'
          aria-label='modal title'
        >
          {title}
          <div className='modal__icon flex align-items-center justify-content-center'>
            <CloseIcon aria-label='close' onClick={handleClose} />
          </div>
        </DialogTitle>

        <DialogContent dividers className='modal__content' style={{ height: innerHeight }}>
          {children}
        </DialogContent>
      </Dialog>
    </>
  );
});

export default Modal;
