import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAppSettings } from 'context/appsettingsContext';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import FormRow from 'components/FormRow/FormRow';
import Button from 'components/Button/Button';

interface installation {
    id: number;
    vehicleId: number;
    currentStep: number;
    dateCompleted: string;
    installationSteps: installationStep[];
}

interface installationStep {
    id: number;
    stepNumber: number;
    description: string;
    verified: boolean;
    dateCompleted: string;
}

interface deviceEventGpsLocation {
    deviceEventId: number;
    latitudeDegrees: number;
    longitudeDegrees: number;
    altitudeMeters: number;
    dataDateTime: string;
    // deviceEvent: any;
}

interface deviceEventMotorState {
    deviceEventId: number;
    motorStateId: number;
    // actionId: string;
    // changedByAdminUser: boolean;
    dataDateTime: string;
    // lastKnownDeviceEventGpsLocationId: number;
    // deviceEvent: any;
    motorState: any;
    // lastKnownDeviceEventGpsLocation: any;
}

interface DeviceEventTamperInfo {
    deviceEventId: number;
    // gpsStateId: number;
    carBatteryStateId: number;
    cancelled: boolean;
    // cancelledByUserId: number | null;
    // cancelledOnDateTime: string | null;
    dataDateTime: string;
    // lastKnownDeviceEventGpsLocationId: number | null;
    // deviceEvent: any;
    // gpsState: any;
    carBatteryState: any;
    // cancelledByUser: any | null;
    // lastKnownDeviceEventGpsLocation: any | null;
}

interface DeviceEventErrorInfo {
    deviceEventId: number;
    errorMessage: string;
    dataDateTime: string;
    // deviceEvent: any;
}

export default function InstallationChecklistForm() {
    const settings = useAppSettings();

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        deviceSerialNumber: '',
        vehicleRegistration: '',
        passcode: '',
    });

    const [formErrors, setFormErrors] = useState({
        deviceSerialNumber: [],
        vehicleRegistration: [],
        passcode: [],
    });

    const [installation, setInstallation] = useState<installation | null>(null);
    const [installationStatus, setInstallationStatus] = useState<string | null>(null);
    const [deviceEventStatus, setDeviceEventStatus] = useState<string | null>(null);
    const [deviceEventPresent, setDeviceEventPresents] = useState(false);
    const [deviceEventHasLatLong, setDeviceEventHasLatLong] = useState(false);
    const [deviceEventGpsLocation, setDeviceEventGpsLocation] = useState<deviceEventGpsLocation | null>(null);
    const [gpsInfo, setGpsInfo] = useState<deviceEventGpsLocation[] | null>([]);
    const [motorStateInfo, setMotorStateInfo] = useState<deviceEventMotorState[] | null>([]);
    const [tamperInfo, setTamperInfo] = useState<DeviceEventTamperInfo[] | null>([]);
    const [errorInfoIgnition, setErrorInfoIgnition] = useState<DeviceEventErrorInfo[] | null>([]);
    const [errorInfoMotor, setErrorInfoMotor] = useState<DeviceEventErrorInfo[] | null>([]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [id]: value }));
    };

    const addOrUpdateStateArray = (eventType: string, event: any) => {
        if (event == null) {
            return;
        }

        // Switch on the event type
        switch (eventType) {
            case "gps":
                // Check if the event already exists in the array
                if (gpsInfo && event.latitudeDegrees != null) {
                    const index = gpsInfo.findIndex((gps) => gps.deviceEventId === event.deviceEventId);
                    if (index === -1) {
                        // Add the new event to the array
                        setGpsInfo((prevState) => {
                            if (prevState) {
                                return [...prevState, event];
                            } else {
                                return [event];
                            }
                        });
                    } else {
                        // Update the existing event in the array
                        setGpsInfo((prevState) => {
                            if (prevState) {
                                return prevState.map((gps, i) => {
                                    if (i === index) {
                                        return event;
                                    } else {
                                        return gps;
                                    }
                                });
                            } else {
                                return [event];
                            }
                        });
                    }
                }
                break;
            case "motorState":
                // Check if the event already exists in the array
                if (motorStateInfo) {
                    const index = motorStateInfo.findIndex((motorState) => motorState.deviceEventId === event.deviceEventId);
                    if (index === -1) {
                        // Add the new event to the array
                        setMotorStateInfo((prevState) => {
                            if (prevState) {
                                return [...prevState, event];
                            } else {
                                return [event];
                            }
                        });
                    } else {
                        // Update the existing event in the array
                        setMotorStateInfo((prevState) => {
                            if (prevState) {
                                return prevState.map((motorState, i) => {
                                    if (i === index) {
                                        return event;
                                    } else {
                                        return motorState;
                                    }
                                });
                            } else {
                                return [event];
                            }
                        });
                    }
                }
                break;
            case "errorInfoIgnition":
                // Check if the event already exists in the array
                if (errorInfoIgnition) {
                    const index = errorInfoIgnition.findIndex((error) => error.deviceEventId === event.deviceEventId);
                    if (index === -1) {
                        // Add the new event to the array
                        setErrorInfoIgnition((prevState) => {
                            if (prevState) {
                                return [...prevState, event];
                            } else {
                                return [event];
                            }
                        });
                    } else {
                        // Update the existing event in the array
                        setErrorInfoIgnition((prevState) => {
                            if (prevState) {
                                return prevState.map((error, i) => {
                                    if (i === index) {
                                        return event;
                                    } else {
                                        return error;
                                    }
                                });
                            } else {
                                return [event];
                            }
                        });
                    }
                }
                break;
            case "errorInfoMotor":
                // Check if the event already exists in the array
                if (errorInfoMotor) {
                    const index = errorInfoMotor.findIndex((error) => error.deviceEventId === event.deviceEventId);
                    if (index === -1) {
                        // Add the new event to the array
                        setErrorInfoMotor((prevState) => {
                            if (prevState) {
                                return [...prevState, event];
                            } else {
                                return [event];
                            }
                        });
                    } else {
                        // Update the existing event in the array
                        setErrorInfoMotor((prevState) => {
                            if (prevState) {
                                return prevState.map((error, i) => {
                                    if (i === index) {
                                        return event;
                                    } else {
                                        return error;
                                    }
                                });
                            } else {
                                return [event];
                            }
                        });
                    }
                }
                break;
            case "tamperInfo":
                // Check if the event already exists in the array
                if (tamperInfo) {
                    const index = tamperInfo.findIndex((tamper) => tamper.deviceEventId === event.deviceEventId);
                    if (index === -1) {
                        // Add the new event to the array
                        setTamperInfo((prevState) => {
                            if (prevState) {
                                return [...prevState, event];
                            } else {
                                return [event];
                            }
                        });
                    } else {
                        // Update the existing event in the array
                        setMotorStateInfo((prevState) => {
                            if (prevState) {
                                return prevState.map((motorState, i) => {
                                    if (i === index) {
                                        return event;
                                    } else {
                                        return motorState;
                                    }
                                });
                            } else {
                                return [event];
                            }
                        });
                    }
                }
                break;
        }

    }

    const getLatestGpsData = async () => {
        try {
            const response = await axios.get(
                `${settings.baseURL}/Installations/GetLatestGpsEvent/${formData.deviceSerialNumber}`,
                {
                    headers: {
                        'X-Passcode': formData.passcode,
                    },
                }
            );

            if (response.data) {
                // setGpsInfo(response.data);
                // console.log("gps data: ", response.data);
                addOrUpdateStateArray("gps", response.data)
            }
        } catch (error) {
            console.error("Error getting GPS data: ", error);
        }
    }

    const getLatestMotorStateData = async () => {
        try {
            const response = await axios.get(
                `${settings.baseURL}/Installations/GetLatestMotorStateEvent/${formData.deviceSerialNumber}`,
                {
                    headers: {
                        'X-Passcode': formData.passcode,
                    },
                }
            );

            if (response.data) {
                // setMotorStateInfo(response.data);
                addOrUpdateStateArray("motorState", response.data)
            }
        } catch (error) {
            console.error("Error getting motor state data: ", error);
        }
    }

    const getLatestTamperInfoData = async () => {
        try {
            const response = await axios.get(
                `${settings.baseURL}/Installations/GetLatestTamperInfoEvent/${formData.deviceSerialNumber}`,
                {
                    headers: {
                        'X-Passcode': formData.passcode,
                    },
                }
            );

            if (response.data) {
                // setTamperInfo(response.data);
                addOrUpdateStateArray("tamperInfo", response.data)
            }
        } catch (error) {
            console.error("Error getting tamper info data: ", error);
        }
    }

    const getLatestErrorInfoData = async (errorType: string) => {
        try {
            const response = await axios.get(
                `${settings.baseURL}/Installations/GetLatestErrorInfoEvent/${formData.deviceSerialNumber}`,
                {
                    headers: {
                        'X-Passcode': formData.passcode,
                    },
                }
            );

            if (response.data) {
                // setErrorInfo(response.data);
                addOrUpdateStateArray(errorType, response.data)
            }
        } catch (error) {
            console.error("Error getting error info data: ", error);
        }
    }

    const getRequiredInfo = async (stepNumber: number) => {
        // Get any required info from the BE
        if (stepNumber === 1) {
            // Get motor state data
            getLatestMotorStateData();
        }

        if (stepNumber === 2) {
            // Get error info data
            getLatestErrorInfoData("errorInfoIgnition");
        }

        if (stepNumber === 3) {
            // Get error info data
            getLatestErrorInfoData("errorInfoMotor");
        }

        if (stepNumber === 4) {
            // Get tamper info data
            getLatestTamperInfoData();
        }
        
        // Note: in the future we can also get updated events via
        // the SignalR context
    }

    const getInstallation = async (id: number) => {
        const getResponse = await axios.get(
            `${settings.baseURL}/Installations/${id}`,
            {
                headers: {
                    'X-Passcode': formData.passcode,
                },
            }
        );

        // console.log("GET response data: ", getResponse.data);

        if (getResponse.data === null) {
            // Alert the response message if response is null
            alert("There was an error getting the installation details.");
        } else {
            getRequiredInfo(getResponse.data.currentStep);
            setInstallation({ id: id, ...getResponse.data, });
        }
    }

    const handleFormSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        setIsSubmitting(true);

        try {
            const postResponse = await axios.post(
                `${settings.baseURL}/Installations/ByVehicleRegistration`,
                {
                    VehicleRegistration: formData.vehicleRegistration,
                    InstallationBookingId: null,
                },
                {
                    headers: {
                        'X-Passcode': formData.passcode, // Use the passcode from the form data
                    },
                }
            );

            // console.log("POST response: ", postResponse);

            if (postResponse.data) {
                // console.log("POST response data: ", postResponse.data);

                if (postResponse.data.id === null) {
                    // Alert the response message if id is null
                    alert(postResponse.data.message);
                } else {
                    // If there is a message in the response, show it in the Installation Details
                    if (postResponse.data.message) {
                        setInstallationStatus(postResponse.data.message);
                    }

                    // Use the id from the POST response
                    getInstallation(postResponse.data.id);
                }
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                const errors = error?.response?.data?.errors;

                if (errors) {
                    setFormErrors(errors);
                }
            }
        }

        setIsSubmitting(false);
    };

    const handleConfirmStep = async (step: installationStep) => {
        if (installation && installation.id) {
            try {
                const response = await axios.post(
                    `${settings.baseURL}/Installations/${installation.id}/UpdateInstallationStep`,
                    {
                        StepNumber: step.stepNumber,
                        Verified: true,
                        DateCompleted: new Date().toISOString(),
                    },
                    {
                        headers: {
                            'X-Passcode': formData.passcode,
                        },
                    }
                );

                if (response.data) {
                    // console.log("UpdateInstallationStep response data: ", response.data);

                    // Call the AddInstallationStepAsync endpoint with the next stepNumber
                    const addStepResponse = await axios.post(
                        `${settings.baseURL}/Installations/${installation.id}/AddInstallationStep`,
                        {
                            StepNumber: step.stepNumber + 1, // Next stepNumber
                        },
                        {
                            headers: {
                                'X-Passcode': formData.passcode,
                            },
                        }
                    );

                    // console.log("AddInstallationStep response data: ", addStepResponse.data);

                    if (addStepResponse.data.id != null) {
                        // Update the local state with the new data
                        // NOTE: it would be better to just return the updated installationSteps data
                        // from the AddInstallationStepAsync endpoint and update the local state with that
                        // This is how that would be done
                        // setInstallation((prevInstallation) => {
                        //     if (prevInstallation) {
                        //         return {
                        //             ...prevInstallation,
                        //             installationSteps: [...prevInstallation.installationSteps, addStepResponse.data],
                        //         };
                        //     } else {
                        //         return prevInstallation;
                        //     }
                        // });

                        // For now we fetch the whole installation again
                        getInstallation(installation.id);
                    }
                    else {
                        alert("There was an error adding the next installation step: " + addStepResponse.data.message);
                    }
                }
            } catch (error) {
                console.error("Error updating installation step: ", error);
            }
        }
    };

    const checkDeviceEvents = async () => {
        if (!formData.deviceSerialNumber) {
            setDeviceEventPresents(false);
            setDeviceEventStatus("Error: Device Serial Number is required.");
            return;
        }

        setDeviceEventStatus("Updating...");

        try {
            const response = await axios.get(
                `${settings.baseURL}/Installations/HasEventInLastHour/${formData.deviceSerialNumber}`,
                {
                    headers: {
                        'X-Passcode': formData.passcode,
                    },
                }
            );

            if (response.status === 200 && response.data) {
                setDeviceEventPresents(true);
                setDeviceEventHasLatLong(true);
                setDeviceEventGpsLocation(response.data);

            } else {
                setDeviceEventPresents(false);
                setDeviceEventStatus("Error: Device has NOT sent any event in the last hour.");
            }
        } catch (error) {
            console.error("Error checking device events: ", error);
            setDeviceEventPresents(false);
            setDeviceEventStatus("Error checking device events.");
        }
    };

    function renderPrecheck() {
        return <div style={{
            textAlign: "center",
        }} >
            <h4>
                <u>Precheck:</u>
            </h4>
            <p style={{ marginTop: 5, fontSize: 14, color: "grey", }}>
                Make sure that the device has sent an Event and is ready.
            </p>
            <FormRow>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ width: 200 }}>
                        Device Serial Number:
                    </label>
                    <input
                        type="text"
                        id="deviceSerialNumber"
                        value={formData.deviceSerialNumber}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                {formErrors.deviceSerialNumber && (
                    <p>{formErrors.deviceSerialNumber}</p>
                )}

                <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                    <div style={{ width: 200, marginTop: 20, }}>
                        <Button
                            label={isSubmitting ? 'Loading...' : 'Check Events'}
                            fullWidth
                            onClick={checkDeviceEvents}
                            disabled={isSubmitting}

                        />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: "center", alignItems: 'center', marginTop: 10, }}>
                    {deviceEventStatus && !deviceEventHasLatLong && (
                        <p style={{ color: deviceEventPresent ? "green" : "red", fontSize: 14, }}>{deviceEventStatus}</p>
                    )}
                    {deviceEventStatus && deviceEventHasLatLong && (
                        <div key={deviceEventGpsLocation!.deviceEventId + "gps"} style={{ margin: 20, display: "flex", justifyContent: "center" }} >
                            <div style={{ border: '1px solid grey', borderRadius: 20, maxWidth: 600, padding: 10, }} >
                                <p style={{ color: "green" }}>Latest GPS Location:</p>
                                <p>Latitude: {deviceEventGpsLocation!.latitudeDegrees}</p>
                                <p>Longitude: {deviceEventGpsLocation!.longitudeDegrees}</p>
                                <p>Altitude: {deviceEventGpsLocation!.altitudeMeters}</p>
                                <p>Date of Event: {formatDate(deviceEventGpsLocation!.dataDateTime)}</p>
                            </div>
                        </div>
                    )
                    }
                </div>

            </FormRow>
        </div>
    }

    function renderChecks() {
        return <div style={{ marginTop: 40, textAlign: "center", }}>
            <h4>
                <u>Installation checks:</u>
            </h4>
            <p style={{ marginTop: 5, fontSize: 14, color: "grey", }}>
                Sequentially test the installation.
            </p>
            <FormRow>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ width: 200 }}>
                        Vehicle Registration:
                    </label>
                    <input
                        type="text"
                        id="vehicleRegistration"
                        value={formData.vehicleRegistration}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                {formErrors.vehicleRegistration && (
                    <p>{formErrors.vehicleRegistration}</p>
                )}
            </FormRow>
            <FormRow>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <label style={{ width: 200 }}>
                        Passcode:
                    </label>
                    <input
                        type="password"
                        id="passcode"
                        value={formData.passcode}
                        onChange={handleInputChange}
                        required
                    />
                </div>
                {formErrors.passcode && (
                    <p>{formErrors.passcode}</p>
                )}
            </FormRow>
        </div>
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-GB', { timeZone: 'Africa/Johannesburg', day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' });
    };

    const renderEventInfo = (eventType: string) => {
        var events = [];

        switch (eventType) {
            case "motorStateInfo":
                for (let i = 0; i < motorStateInfo!.length; i++) {
                    // console.log("motorStateInfo: ", motorStateInfo![i]);
                    events.push(
                        <div key={i + "motorState"} style={{ margin: 20, display: "flex", justifyContent: "center" }} >
                            <div style={{ border: '1px solid grey', borderRadius: 20, maxWidth: 600, padding: 10, }} >
                                {i === motorStateInfo!.length - 1 && <p style={{ color: "green" }}>Latest Motor State:</p>}
                                <p>Motor State: {motorStateInfo![i].motorStateId === 0 ? "unlocked" : "locked"}</p>
                                <p>Date of Event: {formatDate(motorStateInfo![i].dataDateTime)}</p>
                            </div>
                        </div>
                    );
                }
                return events;
            case "errorInfoIgnition":
                for (let i = 0; i < errorInfoIgnition!.length; i++) {
                    // console.log("errorInfo: ", errorInfoIgnition![i]);
                    events.push(
                        <div key={i + "error"} style={{ margin: 20, display: "flex", justifyContent: "center" }} >
                            <div style={{ border: '1px solid grey', borderRadius: 20, maxWidth: 600, padding: 10, }} >
                                {i === errorInfoIgnition!.length - 1 && <p style={{ color: "green" }}>Latest Error Info:</p>}
                                <p>Error Message: {errorInfoIgnition![i].errorMessage}</p>
                                <p>Date of Event: {formatDate(errorInfoIgnition![i].dataDateTime)}</p>
                            </div>
                        </div>
                    );
                }
                return events;
            case "errorInfoMotor":
                for (let i = 0; i < errorInfoMotor!.length; i++) {
                    // console.log("errorInfo: ", errorInfoMotor![i]);
                    events.push(
                        <div key={i + "error"} style={{ margin: 20, display: "flex", justifyContent: "center" }} >
                            <div style={{ border: '1px solid grey', borderRadius: 20, maxWidth: 600, padding: 10, }} >
                                {i === errorInfoMotor!.length - 1 && <p style={{ color: "green" }}>Latest Error Info:</p>}
                                <p>Error Message: {errorInfoMotor![i].errorMessage}</p>
                                <p>Date of Event: {formatDate(errorInfoMotor![i].dataDateTime)}</p>
                            </div>
                        </div>
                    );
                }
                return events;
            case "tamperInfo":
                for (let i = 0; i < tamperInfo!.length; i++) {
                    // console.log("tamperInfo: ", tamperInfo![i]);
                    events.push(
                        <div key={i + "tamper"} style={{ margin: 20, display: "flex", justifyContent: "center" }} >
                            <div style={{ border: '1px solid grey', borderRadius: 20, maxWidth: 600, padding: 10, }} >
                                {i === tamperInfo!.length - 1 && <p style={{ color: "green" }}>Latest Tamper Info:</p>}
                                <p>Car Battery State: {tamperInfo![i].carBatteryStateId === 0 ? "disconnected" : "connected"}</p>
                                <p>Cancelled: {tamperInfo![i].cancelled ? "Yes" : "No"}</p>
                                <p>Date of Event: {formatDate(tamperInfo![i].dataDateTime)}</p>
                            </div>
                        </div>
                    );
                }
                return events;
        }
    }

    function renderInstallation() {
        return (
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 10,
                    }}
                >
                    <div
                        style={{
                            color: 'green',
                            fontSize: 12,
                            marginBottom: 10,
                        }}
                    >
                        <i>fetch successful</i>
                    </div>
                    <div>
                        <p><u>Installation Details:</u></p>
                    </div>
                </div>
                <div style={{
                    marginTop: 10,
                    textAlign: "center",
                }}>
                    {installation && (
                        <>
                            <div>
                                <p>Vehicle ID: {installation.vehicleId ?? "N/A"}</p>
                                <p>Current Step: {installation.currentStep ?? "N/A"}</p>
                                <p>Date Completed: {installation.dateCompleted ? formatDate(installation.dateCompleted) : "N/A"}</p>
                                {installationStatus && (
                                    <p style={{ color: 'orange', fontSize: 12, marginTop: 10, }}><i>{installationStatus}</i></p>
                                )}
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: 30,
                                    marginBottom: 10,
                                }}
                            >
                                <div>
                                    <p><u>Installation Steps:</u></p>
                                </div>
                            </div>

                            {installation.installationSteps.map((step) => (
                                <div style={{
                                    marginTop: 20,
                                    marginBottom: 20,
                                }}>
                                    <div
                                        key={step.id}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginRight: 10,
                                            paddingRight: 10,
                                        }}>
                                        <p style={{ paddingRight: 10, }} >Step {step.stepNumber}: {step.description.replaceAll("\n", "\n")}</p>
                                        <Button
                                            label={step.verified ? 'Confirmed' : 'Confirm'}
                                            onClick={() => handleConfirmStep(step)}
                                            disabled={step.verified}
                                        />
                                    </div>
                                    {step.stepNumber === 1 && renderEventInfo("motorStateInfo")}
                                    {step.stepNumber === 2 && renderEventInfo("errorInfoIgnition")}
                                    {step.stepNumber === 3 && renderEventInfo("errorInfoMotor")}
                                    {step.stepNumber === 4 && renderEventInfo("tamperInfo")}
                                    {!step.verified && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginTop: 10,
                                            }} >
                                            <Button
                                                label="Update"
                                                onClick={() => getRequiredInfo(step.stepNumber)}
                                            />
                                        </div>
                                    )}
                                    {step.dateCompleted && (
                                        <div style={{
                                            justifyContent: "center",
                                            textAlign: "center",
                                        }} >
                                            <p
                                                style={{
                                                    color: 'green',
                                                    fontSize: 12,
                                                    marginTop: 10,
                                                    marginBottom: 10,
                                                }} ><i>Date Verified: {formatDate(step.dateCompleted)}</i></p>
                                        </div>
                                    )}
                                    <hr style={{ width: "80%", }} />
                                </div>
                            ))}
                        </>
                    )}
                </div>

                {
                    installation && installation.dateCompleted &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 10,
                        }}
                    >
                        <div
                            style={{
                                marginTop: 10,
                                marginBottom: 10,
                                color: 'green',
                                fontSize: 12,
                            }}
                        >
                            <i>installation completed!</i>
                        </div>
                        <div style={{
                            justifyContent: "center",
                            textAlign: "center",
                        }} >
                            <p
                                style={{
                                    color: 'green',
                                    fontSize: 12,
                                    marginTop: 10,
                                    marginBottom: 10,
                                }} ><i>Date Completed: {formatDate(installation.dateCompleted)}</i></p>
                        </div>
                    </div>
                }
            </div>
        );
    }

    return (
        <FormLayout>
            <FormLayout.Header>
                <p>Installation Checklist</p>
            </FormLayout.Header>
            <FormLayout.Main isNotNarrow={isMobileView}>
                {
                    renderPrecheck()
                }

                {
                    deviceEventPresent && renderChecks()
                }

                {
                    installation && renderInstallation()
                }

            </FormLayout.Main>
            <FormLayout.Footer>
                <Button
                    label={isSubmitting ? 'Loading...' : (installation ? 'Update Checklist' : 'Get Checklist')}
                    fullWidth
                    onClick={handleFormSubmit}
                    disabled={isSubmitting}
                />
            </FormLayout.Footer>
        </FormLayout>
    );
};