import './Success.scss';
import { ReactComponent as AppStoreDownload } from 'assets/images/appstore-download.svg';
import { ReactComponent as GooglePlayDownload } from 'assets/images/googleplay-download.svg';
import { useEffect } from 'react';
import IntercomScript from 'components/IntercomScript/IntercomScript';
import MapAndLogoSVG from 'assets/images/map-and-logo.svg';

export default function Success() {
  useEffect(() => {
    document.title = 'Success | Titan Secure';
  });
  return (
    <>
      <div className='fixed-layout fixed-layout--dark success'>
        <div className='fixed-layout__main'>
          <div className='narrow-container narrow-container--center-content narrow-container--center-text'>
            <img src={MapAndLogoSVG} alt='Titan Secure' />
            <h2>Welcome to Titan&nbsp;Secure</h2>
            <p>
              You're now one step closer to securing your vehicle. You’ll need
              to download the ‘Titan&nbsp;Secure’ app from your app store to
              continue.
            </p>
            <div className='download-links'>
              <a
                href='https://apps.apple.com/us/app/titan-secure/id6453760752'
                target='_blank'
                aria-label='download app'
                rel='noreferrer'
              >
                <AppStoreDownload aria-label='image' />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.veagroup.titan'
                target='_blank'
                aria-label='download app'
                rel='noreferrer'
              >
                <GooglePlayDownload aria-label='image' />
              </a>
            </div>
          </div>
        </div>
      </div>
      <IntercomScript />
    </>
  );
}
