import axios from 'axios';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button/Button';
import { FormInput } from 'components/FormInput/FormInput';
import FormRow from 'components/FormRow/FormRow';
import InfoBox from 'components/InfoBox/InfoBox';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { ClientInterface } from 'functions/hooks/useGetUsers';
import { trimInputValue } from 'functions/trimInputValue';
import { REGEX, validateInput } from 'functions/validationUtils';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { FormErrors } from 'types/signUpForm';

export interface ClientDetailType {
  Email: string;
  FirstName: string;
  IdNumber: string;
  IdNumberIsSouthAfrican: boolean;
  LastName: string;
  PhoneCode?: string;
  PhoneNumber: string;
  acceptedTermsAndConditionsAndPolicy: boolean;
  acceptedToAllowInformationToBeUsedToContact: boolean;
}

export function handleOnChange(
  e: ChangeEvent<HTMLInputElement>,
  setClientDetails:
    | Dispatch<SetStateAction<ClientDetailType>>
    | Dispatch<SetStateAction<ClientInterface>>,
  setClientDetailsError: Dispatch<SetStateAction<any>>
) {
  const { id, value, type, checked } = e.target;

  const inputValue = trimInputValue(id, value);

  if (id === 'PhoneNumber') {
    if (REGEX.Numbers.test(inputValue) || !inputValue) {
      setClientDetails((prevState: any) => ({
        ...prevState,
        [id]: inputValue,
      }));
    }
  } else {
    setClientDetails((prevState: any) => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : inputValue,
    }));
  }

  if (id === 'IdNumberIsSouthAfrican') {
    setClientDetails((prevState: any) => ({
      ...prevState,
      IdNumber: '',
    }));

    setClientDetailsError((prevState: any) => ({
      ...prevState,
      IdNumber: [],
    }));
  }
}

export default function AddClientForm() {
  const navigate = useNavigate();
  const settings = useAppSettings();
  const { authorisedUser } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clientDetails, setClientDetails] = useState<ClientDetailType>({
    Email: '',
    FirstName: '',
    IdNumber: '',
    IdNumberIsSouthAfrican: true,
    LastName: '',
    PhoneCode: '27',
    PhoneNumber: '',
    acceptedTermsAndConditionsAndPolicy: true,
    acceptedToAllowInformationToBeUsedToContact: true,
  });

  const [clientDetailsError, setClientDetailsError] = useState<FormErrors>({
    Email: [],
    FirstName: [],
    IdNumber: [],
    LastName: [],
    PhoneNumber: [],
  });

  function handleInputValidation(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setClientDetailsError((prevState) => ({
      ...prevState,
      [id]: validateInput(id, value, clientDetails.IdNumberIsSouthAfrican),
    }));
  }

  async function handleSubmit() {
    const URL = `${settings.baseURL}/Users/AccountHolder`;
    setIsSubmitting(true);

    try {
      const response = await axios.post(URL, clientDetails, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });

      if (response?.status === 200) {
        const { id } = response.data;

        navigate(`/agent/onboarding/client/${id}/add-driver`);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setClientDetailsError(error.response?.data.errors);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <FormLayout>
      <FormLayout.Header>
        <FormLayout.HeaderBack onClose={handleGoBack} />
        <p>Account holder</p>
      </FormLayout.Header>
      <FormLayout.Main>
        <InfoBox
          title='Who is responsible for the account?'
          helperText="Kindly gather the customer's personal details, including their
              full name, contact information, and address. Ensure accurate
              record-keeping for future correspondence and installation
              scheduling."
        />
        <form>
          <FormRow>
            <FormInput
              id='FirstName'
              label='Name'
              value={clientDetails.FirstName}
              onChange={(e) =>
                handleOnChange(e, setClientDetails, setClientDetailsError)
              }
              errorMessage={clientDetailsError?.FirstName}
              onBlur={handleInputValidation}
            />
          </FormRow>
          <FormRow>
            <FormInput
              id='LastName'
              label='Surname'
              value={clientDetails.LastName}
              onChange={(e) =>
                handleOnChange(e, setClientDetails, setClientDetailsError)
              }
              errorMessage={clientDetailsError?.LastName}
              onBlur={handleInputValidation}
            />
          </FormRow>
          <FormRow>
            <FormInput
              id='Email'
              label='Email address'
              value={clientDetails.Email}
              onChange={(e) =>
                handleOnChange(e, setClientDetails, setClientDetailsError)
              }
              errorMessage={clientDetailsError?.Email}
              onBlur={handleInputValidation}
            />
          </FormRow>
          <FormRow>
            <FormInput
              type='tel'
              id='PhoneNumber'
              value={clientDetails.PhoneNumber}
              label='Cellphone number'
              onChange={(e) =>
                handleOnChange(e, setClientDetails, setClientDetailsError)
              }
              helpText='Required format: 0821234567'
              errorMessage={clientDetailsError?.PhoneNumber}
              onBlur={handleInputValidation}
            />
          </FormRow>
          <FormRow>
            <ToggleSwitch
              label='Has a South&nbsp;African ID'
              checked={clientDetails.IdNumberIsSouthAfrican}
              htmlFor='IdNumberIsSouthAfrican'
              onChange={(e) =>
                handleOnChange(e, setClientDetails, setClientDetailsError)
              }
            />
          </FormRow>
          <FormRow>
            <FormInput
              type='text'
              id='IdNumber'
              label={
                clientDetails.IdNumberIsSouthAfrican
                  ? 'South African ID Number'
                  : 'Passport Number'
              }
              value={clientDetails.IdNumber}
              onChange={(e) =>
                handleOnChange(e, setClientDetails, setClientDetailsError)
              }
              errorMessage={clientDetailsError?.IdNumber}
              onBlur={handleInputValidation}
            />
          </FormRow>
        </form>
      </FormLayout.Main>
      <FormLayout.Footer>
        <Button
          label={isSubmitting ? 'Submitting' : 'Next'}
          fullWidth
          onClick={handleSubmit}
          disabled={
            !clientDetails.FirstName ||
            !clientDetails.LastName ||
            !clientDetails.PhoneNumber ||
            !clientDetails.Email ||
            !clientDetails.IdNumber ||
            isSubmitting
          }
        />
      </FormLayout.Footer>
    </FormLayout>
  );
}
