import './InfoBox.scss';
import InfoBoxIcon from 'assets/icons/info-icon.svg';

interface InfoBoxProps {
  /** Optional child elements */
  children?: React.ReactNode;
  /** Helper text */
  helperText?: string;
  /** Info box title */
  title?: string;
}

/** UI component used to alert the user to some information */
export default function InfoBox({ children, title, helperText }: InfoBoxProps) {
  return (
    <div className='info-box'>
      <img
        src={InfoBoxIcon}
        alt='Information icon'
        className='info-box__icon'
      />
      {title && (
        <div className='info-box__title' aria-label='Info box title'>
          {title}
        </div>
      )}
      {helperText && (
        <small className='info-box__helperText' aria-label='Info box help text'>
          {helperText}
        </small>
      )}

      {children}
    </div>
  );
}
