export function getYearOptions() {
  const now = new Date().getUTCFullYear();
  const years = Array(now - (now - 25))
    .fill('')
    .map((_v, idx) => now - idx);

  const options = years.map((year) => {
    return {
      label: year.toString(),
      value: year.toString(),
    };
  });

  options.push({
    label: 'Older than 1999',
    value: '1990',
  });

  return options;
}
