import './DashboardLayout.scss';
import { defaultMenuFor } from 'pages/Agent/menuLinks';
import { agentMainNavigation } from '../../pages/Agent/navigation';
import { Helmet } from 'react-helmet';
import { NavLink, Outlet } from 'react-router-dom';
import { ReactComponent as TitanSecureLogo } from 'assets/images/titan-secure-logo.svg';
import { useAuth } from 'context/useAuth';
import DropdownMenu from 'components/Menu/Menu';
import MenuIconButton from 'components/Button/MenuButton';
import { SignalRContextProvider } from 'context/useSignalR';
import { useEffect, useState } from 'react';

export default function DashboardLayout() {
  const { logout, authorisedUser } = useAuth();
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  return (
    <>
      <Helmet>
        <title>Agent | Titan Secure</title>
      </Helmet>
      <div className='dashboard'>
        <header className='header'>
          <div className='header__left'>
            <div className='header__logo'>
              <NavLink to='/agent' aria-label='Agent dashboard'>
                <TitanSecureLogo aria-label='Titan Secure' />
              </NavLink>
            </div>
            {!isMobileView &&
              <nav className='header__navigation'>
                {agentMainNavigation.map((navItem) => (
                  <NavLink
                    key={navItem.path}
                    to={navItem.path}
                    className={({ isActive }) =>
                      isActive ? 'navigation--active' : ''
                    }
                  >
                    {navItem.label}
                  </NavLink>
                ))}
              </nav>
            }
          </div>
          <div className='header__menus'>
            <DropdownMenu
              links={defaultMenuFor(authorisedUser.userRole)}
              buttonComponent={<MenuIconButton />}
              onMenuClick={(label) => label === 'Logout' && logout()}
            />
          </div>
        </header>
        <main>
          <SignalRContextProvider>
            <Outlet />
          </SignalRContextProvider>
        </main>
      </div>
    </>
  );
}
