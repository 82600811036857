import CompanyList from 'components/CompanyList/CompanyList';
import CompanyCard from 'components/CompanyCard/CompanyCard';
import useGetCompanies from 'functions/hooks/useGetCompanies';
import CircularProgress from '@mui/material/CircularProgress';
import FilterNavigation from 'components/FilterNavigation/FilterNavigation';
import { agentCompaniesFilters } from 'pages/Agent/navigation';
import { clientSortOptions } from 'pages/Agent/menuLinks';
import { useState } from 'react';

export default function AllCompanies() {
  const [ searchTextLowerCase, setSearchTextLowerCase] = useState('');
  const { companies, loading, sortCompanies } = useGetCompanies(
    ""
  );

  return (
    <>
      <FilterNavigation
        filters={agentCompaniesFilters}
        onSortChange={sortCompanies}
        sortOptions={clientSortOptions}
        onSearchChange={searchText => setSearchTextLowerCase(searchText?.toLocaleLowerCase()?.trim())}
      />
      <CompanyList>
        {loading && (
          <div className='client-list__loader'>
            <CircularProgress />
          </div>
        )}
        {!loading &&
          companies
          .filter((company) => {
            return !searchTextLowerCase ||
            company.name?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1
          })
          .map((company) => (
            <CompanyCard
              key={company.id}
              companyData={{
                id: company.id,
                name: company.name,
                companyUsers: company.companyUsers,
                vehicles: company.vehicles,
              }}
            />
          ))}
      </CompanyList>
    </>
  );
}
