import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';

interface Props {
  children: React.ReactNode;
}

export const AppSettingsContext = React.createContext({
  baseURL: '',
  intercomAppId: '',
  turnstileSiteKey: '',
  googleMapsApiKey: '',
  tempProtectedRoutesKey: '',
});

export const AppSettingsContextProvider = ({ children }: Props) => {
  const [appSettings, setAppSettings] = useState({
    baseURL: '',
    intercomAppId: '',
    turnstileSiteKey: '',
    googleMapsApiKey: '',
    tempProtectedRoutesKey: '',
  });

  const getSettings = async () => {
    try {
      const response = await axios.get('./appsettings.json');
      setAppSettings(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <AppSettingsContext.Provider value={{ ...appSettings }}>
      {children}
    </AppSettingsContext.Provider>
  );
};

export function useAppSettings() {
  const context = useContext(AppSettingsContext);
  return context;
}