import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as SelectArrowIcon } from 'assets/icons/select-arrow.svg';
import { ChangeEvent, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useAppSettings } from 'context/appsettingsContext';
import { usePreviouslySelectedInstallation } from 'context/usePreviouslySelectedInstallation';
import { useAuth } from 'context/useAuth';
import axios from 'axios';
import Button from 'components/Button/Button';
import Flatpickr from 'react-flatpickr';
import FormInputSelect, {
  SelectOptionType,
} from 'components/FormInputSelect/FormInputSelect';
import FormRow from 'components/FormRow/FormRow';
import InfoBox from 'components/InfoBox/InfoBox';
import useGetFitmentCentres from 'functions/hooks/useGetFitmentCentres';
import useGetRegions from 'functions/hooks/useGetRegions';
import useGetVehicles from 'functions/hooks/useGetVehicles';
import CircularProgress from '@mui/material/CircularProgress';
import InstallationAvailabilityCard from 'components/InstallationAvailabilityCard/InstallationAvailabilityCard';
import CheckBox from 'components/CheckBox/CheckBox';
import { FormInput } from 'components/FormInput/FormInput';
import { Device } from 'types/Device';

export default function InstallationForm() {
  const { authorisedUser } = useAuth();
  const { clientId } = useParams();

  const { fitmentCentres } = useGetFitmentCentres();
  const { regions } = useGetRegions();
  const { vehicles, loadingVehicles } = useGetVehicles(`id eq ${clientId}`);
  const navigate = useNavigate();
  const settings = useAppSettings();

  const currentVehicle = vehicles[0];

  const [filteredCentres, setFilteredCentres] = useState([]);
  const [calendarIsOpen, setCalendarIsOpen] = useState(false);
  const [selectedFitmentCentre, setSelectedFitmentCentre] = useState({
    value: '',
    label: '',
  });
  const [selectedRegion, setSelectedRegion] = useState({
    value: '',
    label: '',
  });

  const [selectedInstallationDate, setSelectedInstallationDate] =
    useState<Date | null>(null);

  const [ _, setInstallationBooking ] = usePreviouslySelectedInstallation();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const [existingInstallation, setExistingInstallation] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleInitialValues = useCallback(() => {
    if (currentVehicle?.installationBookingId) {
      setSelectedRegion({
        value: currentVehicle.regionId?.toString(),
        label: currentVehicle.regionName,
      });
      setSelectedFitmentCentre({
        value: currentVehicle.fitmentCenterId?.toString(),
        label: `${currentVehicle.fitmentCenterArea}, ${currentVehicle.fitmentCenterName}`,
      });

      const filtered: any = fitmentCentres.filter(
        (item: any) => item.regionName === currentVehicle.regionName
      );

      setFilteredCentres(
        filtered.map((item: any) => ({
          value: item.id,
          label: `${item.area}, ${item.name}`,
        }))
      );

      setSelectedInstallationDate(
        new Date(currentVehicle.installationBookingUtcDateTime)
      );
    }
  }, [currentVehicle, fitmentCentres]);

  useEffect(() => {
    handleInitialValues();
  }, [handleInitialValues]);

  async function handleSelect(option: SelectOptionType) {
    setSelectedRegion((prevState) => ({
      ...prevState,
      ...option,
    }));

    setSelectedFitmentCentre((prevState) => ({
      ...prevState,
      ...{ value: '', label: '' },
    }));

    const filtered: any = fitmentCentres.filter(
      (item: any) => item.regionName === option.label
    );
    setFilteredCentres(
      filtered.map((item: any) => ({
        value: item.id,
        label: `${item.area}, ${item.name}`,
      }))
    );
  }

  function handleCentreSelect(option: SelectOptionType) {
    setSelectedFitmentCentre((prevState) => ({
      ...prevState,
      ...option,
    }));
  }

  const [devices, setDevices] = useState<Device[]>([]);
  const [deviceErrors, setDeviceErrors] = useState('');
  const [searchingDevice, setSearchingDevice] = useState(false);
  const [loadingDeviceSearch, setLoadingDeviceSearch] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<Device>({
    id: 0,
    active: false,
    serialNumber: ''
  });

  async function getDevices(searchText: string) {
    try {

      // show progress

      setLoadingDeviceSearch(true);

      // fetch

      const URL = `${settings.baseURL}/Devices?$filter=VehicleId eq 0 and contains(SerialNumber, '${searchText}')&$pagesize=50&$orderby=SerialNumber asc`;
  
      const response = await axios.get(URL, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });

      setDevices(response.data.items);

    } catch (error) {

      if (axios.isAxiosError(error)) {
        console.log(error); //TODO:User has to know
      }

    } finally {

      // hide progress

      setLoadingDeviceSearch(false);
    }
  }

  function handleDeviceSearch(event: ChangeEvent<HTMLInputElement>) {

    var { value } = event.target;

    setSelectedDevice(prevState => ({
      ...prevState,
      id: 0,
      active: false,
      serialNumber: value,
    }));

    setDeviceErrors('');

    if (value.length >= 3) {
      setSearchingDevice(true);
      getDevices(value); // fill
    } else {
      setSearchingDevice(false);
      setDevices([]); // clear
    }
  }

  function handleDeviceSelect(device: Device) {

    setSelectedDevice(prevState => ({
      ...prevState,
      ...device
    }));

    setDeviceErrors('');

    setSearchingDevice(false);
  }

  function handleDate(selectedDate: SetStateAction<Date | null>) {
    setSelectedInstallationDate(selectedDate);
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  async function handleUpdate() {
    const formData = {
      fitmentCenterId: Number(selectedFitmentCentre.value),
      installationUtcDateTime: selectedInstallationDate?.toISOString(),
      active: true,
    };

    if (settings.baseURL) {
      setFormSubmitting(true);
      const URL = `${settings.baseURL}/InstallationBookings/${currentVehicle.installationBookingId}`;

      try {
        const response = await axios.put(URL, formData, {
          headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
        });
        if (response?.status === 200) {
          handleNavigationAfterFormSubmit();
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error);
        }
      }
      setFormSubmitting(false);
    }
  }

  async function handleSubmit() {
    const installationBooking = {
      vehicleId: Number(clientId),
      fitmentCenterId: selectedFitmentCentre.value,
      installationUtcDateTime: selectedInstallationDate?.toISOString(),
    };

    const installationBookingRequest = {
      ...installationBooking,
      onboardingExistingClient: existingInstallation,
    }

    setInstallationBooking(installationBooking);
    
    if (settings.baseURL) {
      setFormSubmitting(true);
      const URL = `${settings.baseURL}/InstallationBookings`;

      try {
        // const response = await axios.post(URL, installationBooking, {
        const response = await axios.post(URL, installationBookingRequest, {
          headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
        });

        if (response?.status === 200) {
          // Link device to vehicle
          if (existingInstallation) {
            if (selectedDevice.serialNumber === '') {
              // Show error with device linking
              setDeviceErrors('Please select a device to link');
              return;
            }

            const deviceData = {
              deviceSerialNumber: selectedDevice.serialNumber,
              vehicleNickname: "My " + currentVehicle.model ?? "Secure Car",
            };

            const deviceURL = `${settings.baseURL}/Vehicles/${Number(clientId)}/LinkDeviceFree`;

            const deviceResponse = await axios.post(
              deviceURL,
              deviceData,
              { headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
            });

            if (deviceResponse?.status !== 200) {
              // Show error with device linking
              setDeviceErrors('Error linking device: ' + deviceResponse.data.message);
              return;
            }
          }
          
          // The device is either already linked previously or current linking succeeded
          handleNavigationAfterFormSubmit();
        }
        else {
          // Error with installation booking
          setDeviceErrors('Error creating installation booking: ' + response.data.message);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error);
        }
      }
      setFormSubmitting(false);
    }
  }

  function handleNavigationAfterFormSubmit() {
    currentVehicle?.subscriptionPlanId === null
      ? navigate(`/agent/onboarding/client/${clientId}/payment`)
      : navigate('/agent/onboarding/scheduled');
  }

  return (
    <>
      <Helmet>
        <link
          rel='stylesheet'
          href='https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css'
        ></link>
      </Helmet>
      <FormLayout>
        <FormLayout.Header>
          <FormLayout.HeaderBack onClose={handleGoBack} />
          <p>
            {currentVehicle?.installationBookingId
              ? 'Reschedule'
              : 'Schedule installation'}
          </p>
        </FormLayout.Header>
        <FormLayout.Main>
       
          {
            !currentVehicle?.installationBookingId &&
            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '20px', }}>
              <Button
                id="BtnNewInstallation"
                onClick={() => {
                  setExistingInstallation(false);
                  setIsCheckboxChecked(false);
                }}
                outline={existingInstallation}
                outlineFilled={!existingInstallation}
                fullWidth
                label='New Installation'
              />
              <Button
                id="BtnExistingInstallation"
                onClick={() => setExistingInstallation(true)}
                outline={!existingInstallation}
                outlineFilled={existingInstallation}
                fullWidth
                label='Existing Installation'
              />
            </div>
          }

          <InfoBox
            title={
              currentVehicle?.installationBookingId
                ? 'Reschedule installation details'
                : 
                  !existingInstallation
                  ? 'Confirm installation with customer'
                  : 'Confirm installation with team'
            }
            helperText={
              currentVehicle?.installationBookingId
                ? "If the customer needs to reschedule their installation date, kindly assist them in finding a new location and installation week. Check the availability of our installation team and offer alternative options that accommodate the customer's schedule."
                :
                  !existingInstallation
                  ? 'Select installation location preferred week of installation. You will confirm it with the customer telephonically.'
                  : 'Make sure that the selected Centre is correct and that the client had their device installed there.'
            }
          />

          {loadingVehicles && (
            <div className='client-list__loader'>
              <CircularProgress />
            </div>
          )}

          {!loadingVehicles && (
            <>
              {!!currentVehicle?.installationAvailabilityFitmentCenterId &&
                !currentVehicle?.installationBookingId && (
                  <FormRow>
                    <InstallationAvailabilityCard
                      fitmentCentres={fitmentCentres}
                      currentVehicle={currentVehicle}
                    />
                  </FormRow>
                )}

              <FormRow>
                <FormInputSelect
                  label='Region'
                  options={regions}
                  value={selectedRegion}
                  handleSelection={handleSelect}
                />
              </FormRow>

              {selectedRegion.value && (
                <FormRow>
                  <FormInputSelect
                    label='Centre'
                    options={filteredCentres}
                    value={selectedFitmentCentre}
                    handleSelection={handleCentreSelect}
                  />
                </FormRow>
              )}

              {currentVehicle?.installationAvailabilityUtcTimeStart &&
                !currentVehicle?.installationBookingId && (
                  <FormRow>
                    <InstallationAvailabilityCard
                      currentVehicle={currentVehicle}
                    />
                  </FormRow>
                )}

              <FormRow>
                <div className='form-field date-time'>
                  <Flatpickr
                    placeholder='Confirm date and time'
                    data-enable-time
                    value={selectedInstallationDate ?? ''}
                    options={{ minDate: existingInstallation ? 0 : new Date() }}
                    onChange={([date]) => {
                      handleDate(date);
                    }}
                    onOpen={() => setCalendarIsOpen(true)}
                    onClose={() => setCalendarIsOpen(false)}
                  />
                  <label>Date and time</label>
                  <div
                    className={`select__icon ${
                      calendarIsOpen ? 'select__icon--open' : ''
                    }`}
                  >
                    <SelectArrowIcon />
                  </div>
                </div>
              </FormRow>

              {
                existingInstallation &&
                <div>
                  <FormRow>
                    <div className='form-field-search'>
                      <FormInput
                        id='Device'
                        label='Device'
                        onChange={handleDeviceSearch}
                        value={selectedDevice.serialNumber}
                        helpText='Enter 3 characters to start the search'
                      />
                      {searchingDevice && (
                        <div className='results'>
                          {loadingDeviceSearch ? (
                            <div className='results-loader'>
                              <CircularProgress size='2rem' color='inherit' />
                            </div>
                          ) : (
                            <>
                              {devices.length === 0 && (
                                <small>Device not found</small>
                              )}
                              {devices.map(
                                (device) => (
                                  <option
                                    key={device.id}
                                    value={device.serialNumber}
                                    onClick={() => handleDeviceSelect(device)}
                                    id={device.id.toString()}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {device.serialNumber}
                                  </option>
                                )
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </FormRow>
                  
                  {
                    deviceErrors &&
                    <FormRow>
                      <p style={{ color: 'red' }}>{deviceErrors}</p>
                    </FormRow>
                  }

                  <FormRow>
                    <CheckBox
                      checked={isCheckboxChecked}
                      id='confirmExisting'
                      name='confirmExisting'
                      onChange={(e) => setIsCheckboxChecked(e.target.checked)}
                      label='Confirm that this client has already had their device installed at this Centre?'
                    />
                  </FormRow>
                </div>
              }
            </>
          )}
        </FormLayout.Main>
        <FormLayout.Footer>
          <Button
            label={formSubmitting ? 'Submitting' : 'Next'}
            fullWidth
            onClick={
              currentVehicle?.installationBookingId
                ? handleUpdate
                : handleSubmit
            }
            disabled={
              !selectedRegion.label ||
              !selectedFitmentCentre.label ||
              !selectedInstallationDate ||
              (existingInstallation && !isCheckboxChecked) ||
              (existingInstallation && !devices.find(x => x.serialNumber?.toLocaleLowerCase() === selectedDevice.serialNumber?.toLocaleLowerCase())) ||
              formSubmitting
            }
          />
        </FormLayout.Footer>
      </FormLayout>
    </>
  );
}
