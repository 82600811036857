import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';

import { useAuth } from './useAuth';
import { useAppSettings } from './appsettingsContext';

interface Props {
  children: React.ReactNode;
}

interface SignalRContextType {
  signalGPS: {
    vehicleId: number;
    latitudeDegrees: number;
    longitudeDegrees: number;
  };
  signalLock: {
    vehicleId: number;
    locked: boolean;
  };
}

const initialValues = {
  signalGPS: {
    vehicleId: 0,
    latitudeDegrees: 0,
    longitudeDegrees: 0,
  },
  signalLock: {
    vehicleId: 0,
    locked: false,
  },
};

enum signalType {
  Tamper = 'DeviceTamperMessage',
  Lock = 'DeviceLockUnlockMessage',
  GPS = 'DeviceGpsLocationMessage',
}

const SignalRContext = createContext<SignalRContextType>(initialValues);

export const SignalRContextProvider = ({ children }: Props) => {
  const { authorisedUser } = useAuth();
  const settings = useAppSettings();

  const [connection, setConnection] = useState<null | HubConnection>(null);
  const [signalGPS, setSignalGPS] = useState(initialValues.signalGPS);
  const [signalLock, setSignalLock] = useState(initialValues.signalLock);

  const signal = useMemo(
    () => ({ signalGPS, signalLock }),
    [signalGPS, signalLock]
  );

  useEffect(() => {
    if (settings.baseURL) {
      const connect = new HubConnectionBuilder()
        .withUrl(`${settings.baseURL}/DefaultHub`, {
          accessTokenFactory: () => authorisedUser.accessToken,
        })
        .withAutomaticReconnect()
        .configureLogging(LogLevel.None)
        .build();

      setConnection(connect);
    }
  }, [authorisedUser.accessToken, settings.baseURL]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          // Connect to GPS message
          connection.on(signalType.GPS, (data) => {
            setSignalGPS((prevState) => ({ ...prevState, ...data }));
          });
          // Connect to Lock message
          connection.on(signalType.Lock, (data) => {
            setSignalLock((prevState) => ({ ...prevState, ...data }));
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);

  return (
    <SignalRContext.Provider value={signal}>{children}</SignalRContext.Provider>
  );
};

export const useSignalR = () => {
  return useContext(SignalRContext);
};
