import './RadioButton.scss';

interface RadioButtonProps {
  /** The current checked state - if the selectedState matches the Id it will be checked */
  selectedState: string;
  /** The chanhe event handled on the parent or label */
  handleChange: (id: string) => any;
  /** Unique Id for the input and label */
  id: string;
  /** Optional label text */
  label?: string;
  /** The value of the gouped name */
  name: string;
  /** The value of the input */
  value: string;
  /** Optional children elements if you dont want to use a label */
  children?: React.ReactNode;
}

/** UI component that represents a radio button, typically used in groups */
export default function RadioButton({
  id,
  name,
  value,
  selectedState,
  handleChange,
  label,
  children,
}: RadioButtonProps) {
  return (
    <label
      className={`
        radio-button
        ${selectedState === id ? 'radio-button--active' : ''}
      `}
      htmlFor={id}
      onClick={() => handleChange(id)}
    >
      <input
        type='radio'
        id={id}
        name={name}
        value={value}
        defaultChecked={selectedState === id}
      />
      {label && <span>{label}</span>}
      {children}
    </label>
  );
}
