export const REGEX = {
  Email: new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/),
  Passport: new RegExp(/^[a-zA-Z0-9]{1,50}$/),
  PhoneNumber: new RegExp(/^(0?)([1-9])(\d{8})$/),
  Numbers: new RegExp(/^\d+$/),
  IdNumberIsSouthAfrican: new RegExp(
    /^(\d\d)(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])(\d{4})([01])(\d)(\d)$/
  ),
};

const ERRORS = {
  Email: ['Please provide a valid email address'],
  PhoneNumber: ['Please provide a valid phone number'],
  IdNumberIsSouthAfrican: ['Please provide a valid ID number'],
  Passport: ['Please provide a valid passport number'],
  Required: ['This field is required'],
};

function identificationNumber(value: string, IdNumberIsSouthAfrican: boolean) {
  if (IdNumberIsSouthAfrican) {
    return REGEX.IdNumberIsSouthAfrican.test(value)
      ? []
      : ERRORS.IdNumberIsSouthAfrican;
  } else {
    return REGEX.Passport.test(value) ? [] : ERRORS.Passport;
  }
}

/**
 *
 * @param id - input id
 * @param value - input value
 */
export function validateInput(id: string, value: string, hasRSAId: boolean) {
  if (!value) return ['This field is required'];

  switch (id) {
    case 'Email':
      return REGEX.Email.test(value) ? [] : ERRORS.Email;
    case 'PhoneNumber':
      return REGEX.PhoneNumber.test(value) ? [] : ERRORS.PhoneNumber;
    case 'IdNumber':
      return identificationNumber(value, hasRSAId);
    default:
      return [];
  }
}
