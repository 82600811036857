import { BadgeTypes } from 'components/Badge/Badge';
import DotMenuButton from 'components/Button/DotMenuButton';
import DropdownMenu from 'components/Menu/Menu';
import { VehicleInterface } from 'types/VehicleInterface';

import './CompanyCard.scss';

interface CompanyCardProps {
  /** Any data related to the company */
  companyData: {
    id: number;
    name: string;
    companyUsers: {
      id: number;
      userId: number;
      companyId: number;
    }[],
    vehicles: {
      id: number;
      companyId: number;
      make: string;
      model: string;
      year: number;
    }[],
    companyVehicles?: VehicleInterface[];
  };
  /** State of the device eg. locked, unlocked, etc */
  state?: BadgeTypes;
  /** Pass in HTML or Components as props for actions */
  actionButtons?: React.ReactNode;
  /** Can the card expand when clicking on the title */
  expandable?: boolean;
  /** Boolean flag to fetch vehicles that are active or not. Used in the filter params on getVehicles */
  getActiveDevices?: boolean;
}

/** A UI component used to display company data with expand/collapse functionality */
export default function CompanyCard({
  companyData,
  actionButtons,
  expandable = true,
  getActiveDevices = true,
}: CompanyCardProps) {
  const {
    id,
    name,
  } = companyData;

  function handleCardExpanding() {
    // Navigate to company view
    window.location.href = `/agent/companies/${id}/view`;
  }

  const accountMenu = [
    {
      path: `/agent/companies/${id}/view`,
      label: 'View Company'
    },
    {
      path: `/agent/companies/${id}/link-user`,
      label: 'Link a Company User',
    },
  ];

  return (
    <li className='company-card' aria-label='company card'>
      <div className='company-card__header'>
        <div
          onClick={handleCardExpanding}
          className='company-card__header-title'
          aria-label='Card title'
          style={{ cursor: expandable ? 'pointer' : 'default' }}
        >
          <div className='company-card__header-name'>
            {name}
          </div>
        </div>

        {actionButtons}
        <DropdownMenu links={accountMenu} buttonComponent={<DotMenuButton />} />
      </div>
    </li>
  );
}
