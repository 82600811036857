import './LogIn.scss';
import { FormInput } from 'components/FormInput/FormInput';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { Link, useNavigate } from 'react-router-dom';
import { PasswordInput } from 'components/PasswordInput/PasswordInput';
import { useAuth } from 'context/useAuth';
import { useEffect, useState } from 'react';
import Button from 'components/Button/Button';
import FormRow from 'components/FormRow/FormRow';
import NumberOne from 'assets/icons/number-one.svg';

export default function LogIn() {
  const { authorisedUser, handleLogin, errors, submitting } = useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    document.title = 'Login | Titan Secure';

    if (authorisedUser) {
      navigate('/agent');
    }
  });

  function submitDisabled() {
    return formData.email.length === 0 || formData.password.length === 0;
  }

  function handleInputChange(e: any) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  }

  return (
    <div className='login'>
      <FormLayout>
        <FormLayout.Main>
          <div className='narrow-container--center-text'>
            <img src={NumberOne} alt='Number 1' />
            <h2>
              SA’s 1st <br /> Multi-layered <br /> Vehicle Safety Solution
            </h2>
          </div>
          <FormRow>
            <FormInput
              id='email'
              type='email'
              label='Email address'
              onChange={handleInputChange}
              value={formData.email}
            />
          </FormRow>
          <FormRow>
            <PasswordInput
              id='password'
              label='Your password'
              onChange={handleInputChange}
              value={formData.password}
            />
            <small className='form-field__message form-field__message--error'>
              {errors}
            </small>
          </FormRow>
          <div className='login__links'>
            <Link to='/forgot-password' aria-label='Forgot password'>
              Forgot password
            </Link>
          </div>
        </FormLayout.Main>
        <FormLayout.Footer>
          <Button
            label='Login'
            fullWidth
            disabled={submitDisabled() || submitting}
            onClick={() => handleLogin(formData)}
          />
        </FormLayout.Footer>
      </FormLayout>
    </div>
  );
}
