import { REGEX, validateInput } from 'functions/validationUtils';
import { Steps } from 'types/signUpForm';
import { trimInputValue } from 'functions/trimInputValue';
import { useAppSettings } from 'context/appsettingsContext';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import IntercomScript from 'components/IntercomScript/IntercomScript';
import StepDetails from './SignUpSteps/StepDetails';
import StepEmail from './SignUpSteps/StepEmail';
import StepPassword from './SignUpSteps/StepPassword';
import { Turnstile } from '@marsidev/react-turnstile';
import type { TurnstileInstance } from '@marsidev/react-turnstile';

export default function SignUpForm() {
  const ref = useRef<TurnstileInstance>(null);
  const settings = useAppSettings();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState('email');
  const [formData, setFormData] = useState({
    AcceptedTermsAndConditionsAndPolicy: false,
    AcceptedToAllowInformationToBeUsedToContact: false,
    Email: '',
    FirstName: '',
    IdNumber: '',
    IdNumberIsSouthAfrican: true,
    LastName: '',
    Password: '',
    PhoneCode: '27',
    PhoneNumber: '',
    turnstileToken: '',
  });

  const [formErrors, setFormErrors] = useState({
    Email: [],
    AcceptedTermsAndConditionsAndPolicy: [],
    AcceptedToAllowInformationToBeUsedToContact: [],
    Password: [],
    FirstName: [],
    LastName: [],
    PhoneNumber: [],
    IdNumber: [],
    PhoneCode: [],
  });

  useEffect(() => {
    document.title = 'Sign up | Titan Secure';
  });

  async function handleUserSignUp() {
    sessionStorage.setItem(
      'userPhoneNumber',
      JSON.stringify({
        PhoneNumber: formData.PhoneNumber,
        PhoneCode: formData.PhoneCode,
      })
    );

    setIsSubmitting(true);

    const URL = `${settings.baseURL}/Auth/SignUp`;

    try {
      const response = await axios.post(URL, formData);
      if (response?.status === 200) {
        navigate('/verify-account');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errors = error?.response?.data?.errors;

        if (errors) {
          setFormErrors(errors);
          if (errors?.Email) {
            setCurrentStep('email');
          } else if (errors?.Password) {
            setCurrentStep('Password');
          } else {
            setCurrentStep('details');
          }
        }
      }
    }

    setIsSubmitting(false);
  }

  /**
   * Handle validations
   */
  function handleInputValidation(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setFormErrors((prevState) => ({
      ...prevState,
      [id]: validateInput(id, value, formData.IdNumberIsSouthAfrican),
    }));
  }

  /**
   * Handle changes on inputs
   */
  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value, type, checked } = e.target;

    const inputValue = trimInputValue(id, value);

    if (id === 'PhoneNumber') {
      if (REGEX.Numbers.test(inputValue) || !inputValue) {
        setFormData((prevState) => ({ ...prevState, [id]: inputValue }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [id]: type === 'checkbox' ? checked : inputValue,
      }));
    }

    if (id === 'IdNumberIsSouthAfrican') {
      handleIdentityReset();
    }
  }

  /**
   * Reset IdNumber field and errors
   */
  function handleIdentityReset() {
    setFormData((prevState) => ({
      ...prevState,
      IdNumber: '',
    }));

    setFormErrors((prevState) => ({
      ...prevState,
      IdNumber: [],
    }));
  }

  /**
   * Next step
   */
  function handleStepChange(step: Steps) {
    setCurrentStep(step);
  }

  function setTurnstileToken(token: string) {
    setFormData((prevState) => ({ ...prevState, turnstileToken: token }));
  }

  const formSteps = () => {
    switch (currentStep) {
      case 'email':
        return (
          <StepEmail
            handleStepChange={handleStepChange}
            formData={formData}
            handleOnChange={handleOnChange}
            formErrors={formErrors}
            handleInputValidation={handleInputValidation}
          />
        );
      case 'password':
        return (
          <StepPassword
            handleStepChange={handleStepChange}
            formData={formData}
            handleOnChange={handleOnChange}
            formErrors={formErrors}
            handleInputValidation={handleInputValidation}
          />
        );
      case 'details':
        return (
          <StepDetails
            handleStepChange={handleStepChange}
            formData={formData}
            handleOnChange={handleOnChange}
            formErrors={formErrors}
            handleInputValidation={handleInputValidation}
            handleSubmit={handleUserSignUp}
            isSubmitting={isSubmitting}
          />
        );
    }
  };

  return (
    <>
      {formSteps()}
      <IntercomScript />
      {settings.turnstileSiteKey && (
        <Turnstile
          siteKey={settings.turnstileSiteKey}
          options={{ size: 'invisible' }}
          ref={ref}
          onSuccess={setTurnstileToken}
        />
      )}
    </>
  );
}
