import './StepTitle.scss';

interface StepTitleProps {
  /** The heading text */
  heading: string;
  /** The sub text */
  text: string;
}

/** UI component to render the heading and text for a step form */
export default function StepTitle({ heading, text }: StepTitleProps) {
  return (
    <div className='title'>
      <h2>{heading}</h2>
      <p>{text}</p>
    </div>
  );
}
