import './CheckBox.scss';

import { ReactComponent as CheckMarkIcon } from '../../assets/icons/check-mark.svg';

interface CheckBoxProps {
  /** The current checked state */
  checked: boolean;
  /** Alternative to label if there is complex markup needed */
  children?: React.ReactNode;
  /** Disabled state */
  disabled?: boolean;
  /** id for input */
  id: string;
  /** Label text */
  label?: string;
  /** name for input */
  name: string;
  /** change handler */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/** UI component for a checkbox */
const CheckBox = ({
  checked,
  children,
  disabled,
  id,
  label,
  name,
  onChange,
}: CheckBoxProps) => {
  return (
    <div className='checkbox'>
      <label htmlFor={id}>
        <input
          type='checkbox'
          name={name}
          id={id}
          checked={checked}
          onChange={onChange}
          aria-label={name}
          disabled={disabled}
          aria-checked={checked}
        />
        <div className='checkbox__indicator'>
          <CheckMarkIcon />
        </div>
        {label ?? children}
      </label>
    </div>
  );
};

export default CheckBox;
