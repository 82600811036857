export const hasSpecialCharacter = (str: string) =>
  /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(str);
export const hasLowercase = (str: string) => str !== str.toUpperCase();
export const hasUppercase = (str: string) => str !== str.toLowerCase();
export const hasOneNumber = (str: string) => /\d/.test(str);
export const isLongerThan8Characters = (str: string) => str.length >= 8;

export interface checklistItem {
  isValid: boolean;
  label: string;
}

export function passwordChecklistItems(password: string) {
  return [
    { label: 'One lowercase character', isValid: hasLowercase(password) },
    { label: 'One uppercase character', isValid: hasUppercase(password) },
    { label: 'One number', isValid: hasOneNumber(password) },
    {
      label: 'One special character',
      isValid: hasSpecialCharacter(password),
    },
    {
      label: '8 character minimum',
      isValid: isLongerThan8Characters(password),
    },
  ];
}

export function checklistPassed(password: string) {
  return (
    hasLowercase(password) &&
    hasUppercase(password) &&
    hasOneNumber(password) &&
    hasSpecialCharacter(password) &&
    isLongerThan8Characters(password)
  );
}
