import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useGetCompanies from 'functions/hooks/useGetCompanies'; // Assuming this hook is created for fetching company details
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import ViewAndLinkUser from '../LinkUser/ViewAndLinkCompanyUsers';

export default function ViewCompany() {

  useEffect(() => {
    document.title = 'View Company | Titan Secure';
  });
  const { companyId } = useParams();
  
  const { companies: company, loading } = useGetCompanies(
    `Roles/Any(r:r eq 'Account-Holder') and id eq ${companyId}`
  );

  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <FormLayout>
      
      <FormLayout.Header>
        <FormLayout.HeaderBack onClose={handleGoBack} />
        <p>View Company</p>
      </FormLayout.Header>

      <FormLayout.Main>
        {loading && (
          <div className='company__loader'>
            <CircularProgress />
          </div>
        )}
        {!loading && company && (
          <ViewAndLinkUser viewOnly={true} companyName={company[0]?.name}/>
        )}

      </FormLayout.Main>

    </FormLayout>
  );
}