import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';

import Button from 'components/Button/Button';
import ClientCard from 'components/ClientCard/ClientCard';
import ClientList from 'components/ClientList/ClientList';
import useGetVehicles from 'functions/hooks/useGetVehicles';
import FilterNavigation from 'components/FilterNavigation/FilterNavigation';
import { agentOnboaringFilters } from 'pages/Agent/navigation';
import { defaultSortOptions } from 'pages/Agent/menuLinks';
import { useState } from 'react';

export default function BookingPending() {
  const [ searchTextLowerCase, setSearchTextLowerCase] = useState('');
  const { vehicles, loadingVehicles, sortVehicles } = useGetVehicles(
    "onBoardingStatus eq 'Booking-Pending'"
  );

  return (
    <>
      <FilterNavigation
        filters={agentOnboaringFilters}
        onSortChange={sortVehicles}
        sortOptions={defaultSortOptions}
        onSearchChange={searchText => setSearchTextLowerCase(searchText?.toLocaleLowerCase()?.trim())}
      />
      <ClientList>
        {loadingVehicles && (
          <div className='client-list__loader'>
            <CircularProgress />
          </div>
        )}
        {!loadingVehicles && vehicles.length !== 0 && (
          <>
            {vehicles
            .filter((vehicle) => {
              return !searchTextLowerCase ||
              vehicle.userFirstName?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1 ||
              vehicle.userLastName?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1 ||
              vehicle.makeVariant?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1 ||
              vehicle.registrationNumber?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1
            })
            .map((item) => (
              <ClientCard
                key={item.id}
                expandable={false}
                clientData={{
                  active: true,
                  firstName: item.userFirstName,
                  id: item.userId,
                  lastName: item.userLastName,
                  makeVariant: item.makeVariant,
                  registrationNumber: item.registrationNumber,
                }}
                actionButtons={
                  <Link to={`/agent/onboarding/client/${item.id}/installation`}>
                    <Button label='Schedule' small />
                  </Link>
                }
              />
            ))}
          </>
        )}
      </ClientList>
    </>
  );
}
