import React from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/close-x.svg';
import { ReactComponent as ArrowBackIcon } from 'assets/icons/arrow-back.svg';
import { Button } from '@mui/material';

interface ChildrenProps {
  isNotNarrow?: boolean;
  children: React.ReactNode;
}

export const FormLayout = ({ children }: ChildrenProps) => {

  return <div className='fixed-layout'>{children}</div>;
};

const Header = ({ children }: ChildrenProps) => (
  <div className='fixed-layout__header'>
    <div className='narrow-container' aria-label='Form header'>
      {children}
    </div>
  </div>
);

interface CloseProps {
  linkPath: string;
  iconType?: 'close' | 'back';
}

interface BackProps {
  onClose?: () => void;
  iconType?: 'close' | 'back';
}

const closeIconTypes = {
  close: <CloseIcon />,
  back: <ArrowBackIcon />,
};

const HeaderCloseButton = ({ linkPath, iconType = 'close' }: CloseProps) => (
  <Button className='form-header-close'>
    {closeIconTypes[iconType]}
  </Button>
);

const HeaderBackButton = ({ onClose, iconType = 'close' }: BackProps) => (
  <Button onClick={onClose} className='form-header-close'>
    {closeIconTypes[iconType]}
  </Button>
);

const Main = ({ isNotNarrow, children }: ChildrenProps) => (
  <div className='fixed-layout__main'>
    <div className={isNotNarrow === false ? "not-narrow-container" : "narrow-container"}>{children}</div>
  </div>
);

interface FooterProps {
  children: React.ReactNode;
  alignment?: 'end' | 'start' | 'space-between';
}

const Footer = ({ children, alignment }: FooterProps) => (
  <div className='fixed-layout__footer'>
    <div
      className={`narrow-container ${
        alignment && `narrow-container-align--${alignment}`
      }`}
    >
      {children}
    </div>
  </div>
);

FormLayout.Header = Header;
FormLayout.HeaderClose = HeaderCloseButton;
FormLayout.HeaderBack = HeaderBackButton;
FormLayout.Main = Main;
FormLayout.Footer = Footer;
