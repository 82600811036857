import './FormInput.scss';

interface FormInputProps {
  /** The error message you want to display */
  errorMessage?: string[];
  /** Unique id for the input and label elements */
  id: string;
  /** Used for both the label and the placeholder */
  label: string;
  /** The onChange can be used to control the input/value  */
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** Optional Select the type of input to be used */
  type?: 'text' | 'tel' | 'email';
  /** The controlled value of the input */
  value: string;
  /** The onBlur event handler */
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** Optional children slot */
  children?: React.ReactNode;
  /** Optional help text for the input */
  helpText?: string;
  /** Visual help text for options field */
  optional?: boolean;
  /** Input in a disabled state */
  disabled?: boolean;
}

/**
 * An general input component used for forms.
 */
export const FormInput = ({
  errorMessage,
  id,
  label,
  onChange,
  type = 'text',
  value,
  onBlur,
  children,
  helpText,
  optional,
  disabled,
}: FormInputProps) => {
  return (
    <div className='form-field'>
      {type === 'tel' && <span className='tel-prefix'>+27</span>}
      <input
        type={type}
        id={id}
        name={id}
        placeholder={label}
        onChange={onChange}
        value={value}
        className={`${
          errorMessage && errorMessage.length !== 0 && 'field-error'
        } ${type === 'tel' && 'tel-input'}`}
        onBlur={onBlur}
        aria-label={label}
        disabled={disabled}
        autoComplete='off'
      />
      <label className={` ${type === 'tel' && 'tel-input'}`} htmlFor={id}>
        {label}
      </label>
      {optional && <small className='optional-text'>Optional</small>}

      {errorMessage &&
        errorMessage.length !== 0 &&
        errorMessage?.map((error) => (
          <small
            key={error}
            role='alert'
            aria-label={`${label} error`}
            className='form-field__message form-field__message--error'
          >
            {error}
          </small>
        ))}
      {helpText && errorMessage && errorMessage.length === 0 && (
        <small aria-label={`${label} help}`} className='form-field__message'>
          {helpText}
        </small>
      )}

      {children}
    </div>
  );
};
