import './FormInputSelect.scss';
import { ReactComponent as SelectArrowIcon } from 'assets/icons/select-arrow.svg';
import { useState } from 'react';

export interface SelectOptionType {
  label: string;
  value: string;
}

interface SelectProps {
  /** Input label text */
  label: string;
  /** Option items */
  options: SelectOptionType[];
  /** Initial selected value */
  value: SelectOptionType;
  /** Handle the selected value */
  handleSelection: (selected: SelectOptionType) => void;
}

/** A select UI component specific to forms */
export default function FormInputSelect({
  options,
  label,
  value,
  handleSelection,
}: SelectProps) {
  const [selectIsOpen, setSelectIsOpen] = useState(false);

  return (
    <div
      className={`form-field select ${selectIsOpen ? 'select--open' : ''}`}
      onClick={() => setSelectIsOpen(!selectIsOpen)}
      aria-label='select'
    >
      <input
        type='text'
        id={label}
        value={value.label}
        readOnly
        placeholder=''
      />
      <label htmlFor={label}>{label}</label>
      <div className={`select__icon ${selectIsOpen && 'select__icon--open'}`}>
        <SelectArrowIcon />
      </div>
      <div
        className='select__backdrop'
        style={{ display: selectIsOpen ? 'block' : 'none' }}
      />
      <div
        className='select__options'
        aria-label='option list'
        style={{ display: selectIsOpen ? 'block' : 'none' }}
      >
        {options.map((option) => (
          <option
            aria-label='option'
            key={option.label}
            className={`select__option ${
              value.label === option.label ? 'select__option--current' : ''
            }`}
            onClick={() => handleSelection(option)}
          >
            {option.label}
          </option>
        ))}
      </div>
    </div>
  );
}
