import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { SubscriptionPlan } from 'types/SubscriptionPlan';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import FormRow from 'components/FormRow/FormRow';
import InfoBox from 'components/InfoBox/InfoBox';
import RadioButton from 'components/RadioButton/RadioButton';
import RadioButtonGroup from 'components/RadioButtonGroup/RadioButtonGroup';
import useAxiosGet from 'functions/hooks/useAxiosGet';
import { InstallationBookingType, usePreviouslySelectedInstallation } from 'context/usePreviouslySelectedInstallation';

interface PaymentRequestBodyType {
  vehicleId: number;
  subscriptionPlanId: number;
  isOffline: boolean;
  initiallyPayDepositOnly: boolean;
  installationBooking: InstallationBookingType | null;
  createdByAgentOnBehalfOfUser: boolean;
}

export default function PaymentForm() {
  const { authorisedUser } = useAuth();
  const { clientId } = useParams();
  const navigate = useNavigate();
  const settings = useAppSettings();
  const { data: subscriptionPlans, loading: loadingPlans } =
    useAxiosGet('SubscriptionPlans');
  const availableSubscriptionPlans: SubscriptionPlan[] = subscriptionPlans.filter((plan: SubscriptionPlan) => plan.available);

  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formError, setFormError] = useState('');

  const [formData, setFormData] = useState({
    subscriptionPlanId: '1',
    termsConsent: false,
    isOffline: false,
    paymentConsent: false,
    marketingConsent: false,
  });

  const [installationBooking, _] = usePreviouslySelectedInstallation();

  // console.log(installationBooking);

  function handleSubscriptionSelect(id: string) {
    setFormData((prevState) => ({
      ...prevState,
      subscriptionPlanId: id,
      isOffline: availableSubscriptionPlans.find((plan) => plan.id === Number(id))?.isOffline ? true : false,
    }));
  }

  function handleConsentCheckBox(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  }

  async function handleSendPayment() {
    setFormSubmitting(true);
    setFormError('');

    const postData: PaymentRequestBodyType = {
      subscriptionPlanId: Number(formData.subscriptionPlanId),
      vehicleId: Number(clientId),
      isOffline: formData.isOffline,
      initiallyPayDepositOnly: true,
      installationBooking: null,
      createdByAgentOnBehalfOfUser: true,
    };

    if (installationBooking.vehicleId) {
      postData.installationBooking = installationBooking;
    }

    if (settings.baseURL) {
      try {

        if (postData.isOffline){
          const URL = `${settings.baseURL}/My/Offline_Subscription`;

          const response = await axios.post(URL, postData, {
            headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
          });

          if (response?.status === 200) {
            navigate('/agent/onboarding/scheduled');
          }
        } else {
          const URL = `${settings.baseURL}/Subscriptions`;

          const response = await axios.post(URL, postData, {
            headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
          });

          if (response?.status === 200) {
            navigate('/agent/onboarding/scheduled');
          }
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setFormError(
            error?.response?.data?.title || error?.response?.data.message
          );
        }
      } finally {
        setFormSubmitting(false);
      }
    }
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <FormLayout>
      <FormLayout.Header>
        <FormLayout.HeaderBack onClose={handleGoBack}  />
        <p>Payment</p>
      </FormLayout.Header>
      <FormLayout.Main>
        <InfoBox
          title='Deposit due: R1 499'
          helperText="This deposit confirms the customer's commitment and enables us to
            allocate the required resources for their installation. As an agent, please
            ensure that the customer completes the deposit payment to proceed to the
            next step in the onboarding process."
        />
        <FormRow>
          <strong>Choose an option</strong>
        </FormRow>
        {!loadingPlans && (
          <FormRow>
            <RadioButtonGroup>
              {availableSubscriptionPlans.map((plan: SubscriptionPlan) => (
                <RadioButton
                  key={plan.id}
                  selectedState={formData.subscriptionPlanId}
                  handleChange={handleSubscriptionSelect}
                  id={`${plan.id}`}
                  name='subscription'
                  value={`${plan.displayName}`}
                >
                  <strong>{plan.displayName}</strong>
                  <small>{plan.description}</small>
                </RadioButton>
              ))}
            </RadioButtonGroup>
          </FormRow>
        )}
        <FormRow>
          <CheckBox
            checked={formData.termsConsent}
            id='termsConsent'
            name='termsConsent'
            onChange={handleConsentCheckBox}
            label='I confirm that I have read and agree to the terms
            and conditions on behalf of the client I am onboarding.'
          />
        </FormRow>
        <FormRow>
          <CheckBox
            checked={formData.paymentConsent}
            id='paymentConsent'
            name='paymentConsent'
            onChange={handleConsentCheckBox}
            label='I confirm that I have read and agree to the payment
            terms on behalf of the client I am onboarding.'
          />
        </FormRow>
        <FormRow>
          <CheckBox
            checked={formData.marketingConsent}
            id='marketingConsent'
            name='marketingConsent'
            onChange={handleConsentCheckBox}
            label='I confirm that I have read and give consent to receive marketing
            and sales communications on behalf of the client I am onboarding.'
          />
        </FormRow>
        {formError && (
          <small className='form-field__message form-field__message--error'>
            {formError}
          </small>
        )}
      </FormLayout.Main>
      <FormLayout.Footer>
        <Button
          label={formSubmitting ? 'Submitting' : 'Send payment link'}
          onClick={handleSendPayment}
          fullWidth
          disabled={
            !formData.paymentConsent ||
            !formData.termsConsent ||
            !formData.marketingConsent ||
            formSubmitting
          }
        />
      </FormLayout.Footer>
    </FormLayout>
  );
}
