import React, { useContext } from 'react';
import { useState } from 'react';

export interface InstallationBookingType {
  vehicleId: number;
  fitmentCenterId: string;
  installationUtcDateTime: string | undefined;
}

interface Props {
  children: React.ReactNode;
}

const initialBooking = {
  vehicleId: 0,
  fitmentCenterId: '0',
  installationUtcDateTime: ''
} as InstallationBookingType;

const PreviouslySelectedInstallationContext = React.createContext<
  [InstallationBookingType, React.Dispatch<React.SetStateAction<InstallationBookingType>>]
>([initialBooking, prevState => prevState])

export const PreviouslySelectedInstallationProvider = ({ children }: Props) => {
  const [installationBooking, setInstallationBooking] = useState(initialBooking)

  return (
    <PreviouslySelectedInstallationContext.Provider value={[installationBooking, setInstallationBooking]}>
      {children}
    </PreviouslySelectedInstallationContext.Provider>
  )
}

export const usePreviouslySelectedInstallation = () => useContext(PreviouslySelectedInstallationContext);
