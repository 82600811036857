import { AgentInterface } from 'types/AgentInterface';
import { FormInput } from 'components/FormInput/FormInput';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { REGEX, validateInput } from 'functions/validationUtils';
import { trimInputValue } from 'functions/trimInputValue';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from 'components/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormInputSelect, {
  SelectOptionType,
} from 'components/FormInputSelect/FormInputSelect';
import FormRow from 'components/FormRow/FormRow';
import React, { useState } from 'react';
import useAxiosGet from 'functions/hooks/useAxiosGet';

export default function AddAgent() {
  const navigate = useNavigate();
  const settings = useAppSettings();
  const { authorisedUser } = useAuth();
  const { data: roles, loading: loadingRoles } = useAxiosGet('Roles');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const roleOptions = roles.map((role: any) => ({
    value: role.id,
    label: role.name,
  }));

  const [currentRole, setCurrentRole] = useState({
    value: '',
    label: '',
  });

  const [formData, setFormData] = useState<AgentInterface>({
    FirstName: '',
    LastName: '',
    Email: '',
    phoneCode: '27',
    PhoneNumber: '',
    RoleName: '',
  });

  const [formErrors, setFormErrors] = useState({
    FirstName: [],
    LastName: [],
    Email: [],
    PhoneNumber: [],
    RoleName: [],
  });

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    const inputValue = trimInputValue(id, value);

    if (id === 'PhoneNumber') {
      if (REGEX.Numbers.test(inputValue) || !inputValue) {
        setFormData((prevState) => ({ ...prevState, [id]: inputValue }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [id]: inputValue,
      }));
    }
  }

  function handleInputValidation(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setFormErrors((prevState) => ({
      ...prevState,
      [id]: validateInput(id, value, false),
    }));
  }

  async function handleSubmit() {
    const URL = `${settings.baseURL}/Users`;

    setIsSubmitting(true);

    try {
      const response = await axios.post(URL, formData, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });
      if (response?.status === 200) {
        navigate('/');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setFormErrors(error.response?.data.errors);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleRoleSelection(role: SelectOptionType) {
    setCurrentRole((prevState) => ({
      ...prevState,
      ...role,
    }));

    setFormData((prevState) => ({
      ...prevState,
      RoleName: role.label,
    }));
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <FormLayout>
      <FormLayout.Header>
        <FormLayout.HeaderBack onClose={handleGoBack} />
        <p>Add agent</p>
      </FormLayout.Header>
      <FormLayout.Main>
        <FormRow>
          <FormInput
            id='FirstName'
            label='Name'
            onChange={handleOnChange}
            value={formData.FirstName}
            onBlur={handleInputValidation}
            errorMessage={formErrors.FirstName}
          />
        </FormRow>
        <FormRow>
          <FormInput
            id='LastName'
            label='Surname'
            onChange={handleOnChange}
            value={formData.LastName}
            onBlur={handleInputValidation}
            errorMessage={formErrors.LastName}
          />
        </FormRow>
        <FormRow>
          <FormInput
            type='tel'
            id='PhoneNumber'
            label='Cellphone number'
            onChange={handleOnChange}
            value={formData.PhoneNumber}
            onBlur={handleInputValidation}
            errorMessage={formErrors.PhoneNumber}
          />
        </FormRow>
        <FormRow>
          <FormInput
            id='Email'
            label='Email address'
            onChange={handleOnChange}
            value={formData.Email}
            onBlur={handleInputValidation}
            errorMessage={formErrors.Email}
          />
        </FormRow>

        <FormRow>
          {loadingRoles && (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress size='2rem' />
            </div>
          )}
          {!loadingRoles && roles.length > 0 && (
            <FormInputSelect
              label='Role'
              options={roleOptions}
              value={currentRole}
              handleSelection={handleRoleSelection}
            />
          )}
        </FormRow>
      </FormLayout.Main>
      <FormLayout.Footer>
        <Button
          label={isSubmitting ? 'Submitting' : 'Add agent'}
          fullWidth
          onClick={handleSubmit}
          disabled={
            !formData.FirstName ||
            !formData.LastName ||
            !formData.Email ||
            !formData.Email ||
            !currentRole.label ||
            isSubmitting
          }
        />
      </FormLayout.Footer>
    </FormLayout>
  );
}
