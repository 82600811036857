import './PasswordChecklist.scss';
import { passwordChecklistItems } from 'functions/passwordUtils';
import { ReactComponent as CheckMarkIcon } from 'assets/icons/check-mark.svg';

interface PasswordChecklistProps {
  password: string;
}

/** UI component that checks if a password meets a list of requirements */
export default function PasswordChecklist({
  password,
}: PasswordChecklistProps) {
  const checkList = passwordChecklistItems(password);

  return (
    <ul className='password-checklist'>
      {checkList.map((item) => (
        <li
          key={item.label}
          className={`password-checklist__item ${
            item.isValid && 'password-checklist__item--valid'
          } `}
          aria-label={`Requirement`}
        >
          <CheckMarkIcon className='password-checklist__icon' />
          <div className='password-checklist__label'>{item.label}</div>
        </li>
      ))}
    </ul>
  );
}
