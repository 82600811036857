import { ReactComponent as EllipsisIcon } from 'assets/icons/ellipsis-icon.svg';

import '../Button/Button.scss';

interface DotMenuButtonProps {
  'aria-expanded'?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function DotMenuButton(props: DotMenuButtonProps) {
  const { 'aria-expanded': ariaExpanded, onClick } = props;

  return (
    <button
      id='basic-button'
      className='menu-button'
      aria-label='dot menu'
      aria-expanded={ariaExpanded}
      onClick={onClick}
    >
      <EllipsisIcon className='button--dots' />
    </button>
  );
}
