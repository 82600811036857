import axios from 'axios';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { useEffect, useState } from 'react';

import { VehicleInterface } from 'types/VehicleInterface';

export default function useGetVehicles(filters?: string) {
  const { authorisedUser } = useAuth();
  const settings = useAppSettings();

  const [vehicles, setVehicles] = useState<VehicleInterface[]>([]);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function getVehicles() {
      if (settings?.baseURL) {
        let URL = `${settings.baseURL}/Vehicles`;

        if (filters) {
          URL = `${settings.baseURL}/Vehicles?$filter=${filters}`;
        }

        setLoadingVehicles(true);

        try {
          const response = await axios.get(URL, {
            headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
          });

          if (response?.status === 200) {
            setVehicles(response.data.items);
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setError(error.message);
          }
        } finally {
          setLoadingVehicles(false);
          sortVehicles('');
        }
      }
    }
    getVehicles();
  }, [authorisedUser.accessToken, filters, settings.baseURL]);

  function sortVehicles(label: string) {
    switch (label) {
      case 'A - Z':
        setVehicles((prevState) => {
          return prevState.sort((a, b) =>
            a.userFirstName.localeCompare(b.userFirstName)
          );
        });
        break;
      case 'Z - A':
        setVehicles((prevState) => {
          return prevState.sort((a, b) =>
            b.userFirstName.localeCompare(a.userFirstName)
          );
        });
        break;
      default:
        setVehicles((prevState) => {
          return prevState.sort((a, b) =>
            b.createdOnDateTime.localeCompare(a.createdOnDateTime)
          );
        });
    }
  }

  return {
    vehicles,
    loadingVehicles,
    error,
    sortVehicles,
  };
}
