import axios from 'axios';
import { useAuth } from 'context/useAuth';
import { useAppSettings } from 'context/appsettingsContext';
import { useEffect, useState } from 'react';

export interface FitmentCentres {
  items?: CentreEntity[];
}
export interface CentreEntity {
  id: number;
  name: string;
  maxInstallationsPerWeek: number;
  openTime: string;
  closeTime: string;
  active: boolean;
  latitude: number;
  longitude: number;
  physicalAddress: string;
  area: string;
  regionId: number;
  regionName: string;
}

export default function useGetFitmentCentres() {
  const { authorisedUser } = useAuth();
  const settings = useAppSettings();

  const [fitmentCentres, setFitmentCentres] = useState<FitmentCentres[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function getFitmentCentres() {
      if (settings?.baseURL) {
        const URL = `${settings?.baseURL}/FitmentCenters`;

        setLoading(true);

        try {
          const response = await axios.get(URL, {
            headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
          });

          if (response?.status === 200) {
            setFitmentCentres(
              response.data.items
                .filter((centre: CentreEntity) => centre.active)
                .map((centre: CentreEntity) => ({ ...centre }))
            );
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setError(error.message);
          }
        } finally {
          setLoading(false);
        }
      }
    }
    getFitmentCentres();
  }, [authorisedUser.accessToken, settings.baseURL]);

  return {
    fitmentCentres,
    loading,
    error,
  };
}
