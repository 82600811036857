import Badge, { BadgeTypes } from 'components/Badge/Badge';
import { VehicleHistoryInterface } from 'types/VehicleHistoryInterface';

import './BadgeCard.scss';

export interface BadgeCardProps {
  /** Badge card vehicle history details */
  details: VehicleHistoryInterface;
}

function formatDate(gpsDateTime: string) {
  const date = new Date(gpsDateTime);
  const options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: '2-digit',
  } as Intl.DateTimeFormatOptions;

  return date.toLocaleString('en-ZA', options);
}

function renderDetail(label: string, value: string | number) {
  return (
    <div className='badge-card__detail' aria-label={`${label.toLowerCase()}`}>
      <strong>{label}:</strong> <span>{value}</span>
    </div>
  );
}

function BadgeState(details?: VehicleHistoryInterface): BadgeTypes {
  if (details) {
    if (
      details.type === 'AuraCallout' ||
      details.type === 'TamperAlert' ||
      details.type === 'CapitalAirIncident'
    ) {
      return 'alert';
    } else if (details.isLocked) {
      return 'locked';
    } else {
      return 'unlocked';
    }
  }
  return 'default';
}

function BadgeCardSection(
  title: string,
  details: VehicleHistoryInterface,
  renderFunction: () => React.JSX.Element
) {
  const badgeType = BadgeState(details);
  const { lastKnownGpsLatitudeDegrees, lastKnownGpsLongitudeDegrees } = details;

  return (
    <div className='badge-card__fields'>
      <div className='badge-card__title'>
        <span aria-label='card title'>{title}</span>
        <Badge aria-label='badge' state={badgeType} />
      </div>

      {renderFunction()}
      {lastKnownGpsLatitudeDegrees && lastKnownGpsLongitudeDegrees && (
        <>
          {renderDetail('Latitude', lastKnownGpsLatitudeDegrees)}
          {renderDetail('Longitude', lastKnownGpsLongitudeDegrees)}
        </>
      )}
    </div>
  );
}

/** UI component containing vehicle history information */
export default function BadgeCard({ details }: BadgeCardProps) {
  const {
    auraCalloutCreatedByUserFirstName,
    auraCalloutCreatedByUserLastName,
    auraCalloutStatus,
    capitalAirIncidentCreatedByUserFirstName,
    capitalAirIncidentCreatedByUserLastName,
    capitalAirIncidentStatus,
    dateTime,
    isLocked,
    isLockedByUserFirstName,
    isLockedByUserLastName,
    isLockedChangedByAdminUser,
    tamperCancelled,
    tamperCancelledByUserFirstName,
    tamperCancelledByUserLastName,
    tamperCarBatteryState,
    tamperGpsState,
    type,
  } = details;
  return (
    <>
      {details && (
        <div className='badge-card' aria-label='history card'>
          {dateTime && (
            <div className='badge-card__date-time' aria-label='date'>
              {formatDate(dateTime)}
            </div>
          )}

          {type === 'AuraCallout' &&
            BadgeCardSection('Aura Callout', details, () => (
              <>
                {auraCalloutStatus && renderDetail('Status', auraCalloutStatus)}
                {auraCalloutCreatedByUserFirstName &&
                  renderDetail(
                    'Callout created by',
                    `${auraCalloutCreatedByUserFirstName} ${auraCalloutCreatedByUserLastName}`
                  )}
              </>
            ))}

          {type === 'LockState' &&
            BadgeCardSection('Lock State', details, () => (
              <>
                {isLockedChangedByAdminUser &&
                  renderDetail(
                    `${isLocked ? 'Locked' : 'Unlocked'} by Admin`,
                    'Yes'
                  )}
                {!isLockedChangedByAdminUser &&
                  isLockedByUserFirstName &&
                  isLockedByUserLastName &&
                  renderDetail(
                    `${isLocked ? 'Locked' : 'Unlocked'} by`,
                    `${isLockedByUserFirstName} ${isLockedByUserLastName}`
                  )}
              </>
            ))}

          {tamperCancelled &&
            BadgeCardSection('Cancelled Alerts', details, () => (
              <>
                {renderDetail('Tamper Alert Cancelled', 'Yes')}
                {tamperCancelledByUserFirstName &&
                  tamperCancelledByUserLastName &&
                  renderDetail(
                    'Alert Cancelled by',
                    `${tamperCancelledByUserFirstName} ${tamperCancelledByUserLastName}`
                  )}
              </>
            ))}

          {type === 'TamperAlert' &&
            BadgeCardSection('Tamper Alerts', details, () => (
              <>
                {tamperCarBatteryState &&
                  renderDetail('Car Battery State', tamperCarBatteryState)}
                {tamperGpsState && renderDetail('GPS State', tamperGpsState)}
              </>
            ))}

          {type === 'CapitalAirIncident' &&
            BadgeCardSection('Capital Air Incident', details, () => (
              <>
                {capitalAirIncidentStatus &&
                  renderDetail('Status', capitalAirIncidentStatus)}
                {capitalAirIncidentCreatedByUserFirstName &&
                  capitalAirIncidentCreatedByUserLastName &&
                  renderDetail(
                    'Created by',
                    `${capitalAirIncidentCreatedByUserFirstName} ${capitalAirIncidentCreatedByUserLastName}`
                  )}
                {tamperGpsState && renderDetail('GPS State', tamperGpsState)}
              </>
            ))}
        </div>
      )}
    </>
  );
}
