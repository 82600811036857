import './Mapview.scss';
import { mapStyle } from './map-style';
import { ReactComponent as CloseIcon } from 'assets/icons/close-x.svg';
import { useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import Button from 'components/Button/Button';
import MapPinAlert from 'assets/icons/map-pin-alert.svg';
import MapPinLocked from 'assets/icons/map-pin-locked.svg';
import MapPinUnlocked from 'assets/icons/map-pin-unlocked.svg';
import { useAppSettings } from 'context/appsettingsContext';

export interface MapviewProps {
  /** Single vehicle information */
  vehicle: {
    lat: number;
    lng: number;
  };
  /** Current tamper alert status */
  tamperAlert?: boolean;
  /** The current lock status */
  isLocked?: boolean;
  /** The notification pop up after a command has been issued */
  commandNotification?: { status: string; message: string };
  /** Command buttons */
  commandButtons?: React.ReactNode;
}

/** A UI component to render the location of a vehicle on a map as well as the device status */
export default function Mapview({
  vehicle,
  tamperAlert,
  isLocked,
  commandNotification,
  commandButtons,
}: MapviewProps) {
  const settings = useAppSettings();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: settings.googleMapsApiKey,
  });

  const [infoWindowOpen, setInforWindowOpen] = useState(false);
  const [coordsCopied, setCoordsCopied] = useState(false);

  const mapPinStatus = {
    alert: MapPinAlert,
    locked: MapPinLocked,
    unlocked: MapPinUnlocked,
  };

  function handleInfoWindow() {
    setInforWindowOpen(!infoWindowOpen);
  }

  function handleCopyToClipboard(e: any) {
    setCoordsCopied(true);
    navigator.clipboard.writeText(`Lat: ${vehicle.lat}, Lng: ${vehicle.lng}`);
    setTimeout(() => {
      setCoordsCopied(false);
      setInforWindowOpen(false);
    }, 1000);
  }

  function renderInfoWindow() {
    return (
      <InfoWindow position={{ lat: vehicle.lat, lng: vehicle.lng }}>
        <div className='info-window'>
          <div
            className='info-window__close'
            onClick={() => setInforWindowOpen(!infoWindowOpen)}
          >
            <CloseIcon />
          </div>
          <p>Lat: {vehicle.lat}</p>
          <p>Lng: {vehicle.lng}</p>
          <Button
            disabled={coordsCopied}
            label={coordsCopied ? 'Copied to clipboard' : 'Copy coordinates'}
            small
            fullWidth
            onClick={(e) => handleCopyToClipboard(e)}
          />
        </div>
      </InfoWindow>
    );
  }

  return (
    <div className='map-view' aria-label='map'>
      {isLoaded && (
        <GoogleMap
          mapContainerClassName='map'
          zoom={16}
          center={{
            lat: vehicle.lat,
            lng: vehicle.lng,
          }}
          options={{ styles: mapStyle, disableDefaultUI: false }}
        >
          {tamperAlert ? (
            <Marker
              position={{ lat: vehicle.lat, lng: vehicle.lng }}
              icon={{ url: mapPinStatus['alert'] }}
              onClick={handleInfoWindow}
            >
              {infoWindowOpen && renderInfoWindow()}
            </Marker>
          ) : (
            <Marker
              position={{ lat: vehicle.lat, lng: vehicle.lng }}
              icon={{ url: mapPinStatus[isLocked ? 'locked' : 'unlocked'] }}
              onClick={handleInfoWindow}
            >
              {infoWindowOpen && renderInfoWindow()}
            </Marker>
          )}
          {commandButtons && (
            <div className='map-view__commands' aria-label='command buttons'>
              {commandButtons}
            </div>
          )}
          {commandNotification && (
            <small
              className={`map-view__notification map-view__notification--${commandNotification.status}`}
            >
              {commandNotification.message}
            </small>
          )}
        </GoogleMap>
      )}
    </div>
  );
}
