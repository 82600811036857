import React from 'react';
import './ClientList.scss';

interface ClientListProps {
  children: React.ReactNode;
}

export default function ClientList({ children }: ClientListProps) {
  return (
    <ul className='client-list' aria-label='client list'>
      {children}
    </ul>
  );
}
