import { VehicleInterface } from 'types/VehicleInterface';

interface Props {
  fitmentCentres?: any;
  fitmentCentreId?: number;
  currentVehicle: VehicleInterface;
}

function formatDate(UTCDate: string) {
  return new Date(UTCDate).toLocaleDateString('default', {
    day: 'numeric',
    month: 'short',
  });
}

function formatTime(UTCTime: string) {
  // A random date used here to converted the supplied time to a local date time then use time only
  const dateAndTimeSetup = `2023-08-09T${UTCTime}.000Z`;
  const time = new Date(dateAndTimeSetup).toLocaleString(undefined, {
    hour: 'numeric',
    hour12: true,
  });

  return time.replace(/\s/g, '');
}

export default function InstallationAvailabilityCard({
  currentVehicle,
  fitmentCentres,
}: Props) {
  const {
    installationAvailabilityUtcDateStart,
    installationAvailabilityUtcDateEnd,
    installationAvailabilityUtcTimeStart,
    installationAvailabilityUtcTimeEnd,
    installationAvailabilityFitmentCenterId,
  } = currentVehicle;

  const year = new Date(
    installationAvailabilityUtcDateStart
  ).toLocaleDateString('default', { year: 'numeric' });

  const fromDate = formatDate(installationAvailabilityUtcDateStart);
  const toDate = formatDate(installationAvailabilityUtcDateEnd);
  const startTime = formatTime(installationAvailabilityUtcTimeStart);
  const endTime = formatTime(installationAvailabilityUtcTimeEnd);
  const availabilityCenter = fitmentCentres?.find(
    (centre: any) => centre.id === installationAvailabilityFitmentCenterId
  );

  return (
    <>
      {fitmentCentres ? (
        <div className='info-card'>
          <p style={{ textAlign: 'left' }}>
            {availabilityCenter?.area}, {availabilityCenter?.regionName}
          </p>
        </div>
      ) : (
        <div className='info-card'>
          <p style={{ textAlign: 'left' }}>
            {`${fromDate} - ${toDate} ${year}`}
          </p>
          <p style={{ textAlign: 'left' }}>
            {startTime.includes('am') ? 'Morning, ' : 'Afternoon, '}
            {startTime} - {endTime}
          </p>
        </div>
      )}
    </>
  );
}
