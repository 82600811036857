import React from 'react';
import ReactDOM from 'react-dom/client';
import './stylesheets/application.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppSettingsContextProvider } from './context/appsettingsContext';
import { PreviouslySelectedInstallationProvider } from 'context/usePreviouslySelectedInstallation';
import App from 'App';
import { AuthProvider } from 'context/useAuth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppSettingsContextProvider>
        <AuthProvider>
          <PreviouslySelectedInstallationProvider>
            <App />
          </PreviouslySelectedInstallationProvider>
        </AuthProvider>
      </AppSettingsContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
