import FilterButton from 'components/Button/FilterButton';
import DropdownMenu, { MenuLink } from 'components/Menu/Menu';

import { ChangeEvent, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import './FilterNavigation.scss';
import { FormInput } from 'components/FormInput/FormInput';

interface FilterType {
  path: string;
  label: string;
  searchText: string;
}

interface FilterNavigationProps {
  /** An array of objects containing the path and label  */
  filters: FilterType[];
  onSortChange?: (label: string) => void;
  onSearchChange?: (searchText: string) => void;
  sortOptions?: MenuLink[];
}

/** A UI component to render the filter navigation */
export default function FilterNavigation({
  filters,
  onSortChange,
  onSearchChange,
  sortOptions,
}: FilterNavigationProps) {
  const [searchText, setSearchText] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('Latest');
  const [selectedSubMenuLabel, setSelectedSubMenuLabel] = useState('Outstanding Details');
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  function handleSorting(label: string) {
    setSelectedLabel(label);
    if (onSortChange) onSortChange(label);
  }

  function handleSearch(e: ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
    if (onSearchChange) onSearchChange(e.target.value);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='filter'>
      {
        isMobileView ?
          (
            <>
              <div className='dropdown-menu'>
                <DropdownMenu
                  links={filters}
                  buttonComponent={
                    <FilterButton label={selectedSubMenuLabel}/>
                  }
                  onMenuClick={(label) => {setSelectedSubMenuLabel(label)}}
                  selectedLabel={selectedSubMenuLabel}
                />
              </div>
              <FormInput
                id='Search'
                label='Search'
                onChange={handleSearch}
                value={searchText}
                />
            </>
          )
          :
          (
            <>
              <nav className='filter-nav' aria-label='filter navigation'>
                {filters.map((filter) => (
                  <NavLink
                    key={filter.label}
                    to={filter.path}
                    className={({ isActive }) => (isActive ? 'filter-nav-active' : '')}
                  >
                    {filter.label}
                  </NavLink>
                ))}
              </nav>
              <FormInput
                id='Search'
                label='Search'
                onChange={handleSearch}
                value={searchText}
                />
            </>
          )
      }


      {sortOptions && (
        <div className='filter-nav__menu'>
          <DropdownMenu
            links={sortOptions}
            buttonComponent={<FilterButton label={selectedLabel} />}
            onMenuClick={(label) => handleSorting(label)}
            selectedLabel={selectedLabel}
          />
        </div>
      )}
    </div>
  );
}
