import { Helmet } from 'react-helmet';
import { useAppSettings } from 'context/appsettingsContext';

export default function IntercomScript() {
  const settings = useAppSettings();
  return (
    <>
      {settings?.intercomAppId && (
        <Helmet>
          <script type='text/javascript'>
            {`window.intercomSettings = {
            api_base: "https://api-iam.intercom.io",
            app_id: "${settings.intercomAppId}"};`}
          </script>
          <script type='text/javascript'>
            {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${settings.intercomAppId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
          </script>
        </Helmet>
      )}
    </>
  );
}
