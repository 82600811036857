import axios from 'axios';
import { useAuth } from 'context/useAuth';
import { useAppSettings } from 'context/appsettingsContext';
import { useEffect, useState } from 'react';

export default function useGetRegions(filters?: string) {
  const { authorisedUser } = useAuth();
  const settings = useAppSettings();

  const [regions, setRegions] = useState([]);
  const [loadingRegions, setLoadingRegions] = useState(false);
  const [error, setError] = useState('');

  interface RegionsResponse {
    id: number;
    name: string;
    active: boolean;
  }

  useEffect(() => {
    async function getRegions() {
      if (settings?.baseURL) {
        const URL = `${settings.baseURL}/Regions`;

        setLoadingRegions(true);

        try {
          const response = await axios.get(URL, {
            headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
          });

          if (response?.status === 200) {
            setRegions(
              response.data.items
                .filter((region: RegionsResponse) => region.active)
                .map((region: RegionsResponse) => ({
                  value: region.id,
                  label: region.name,
                }))
            );
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setError(error.message);
          }
        } finally {
          setLoadingRegions(false);
        }
      }
    }
    getRegions();
  }, [authorisedUser.accessToken, filters, settings.baseURL]);

  return {
    regions,
    loadingRegions,
    error,
  };
}
