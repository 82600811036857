export const agentMainNavigation = [
  { path: '/agent/clients', label: 'Clients' },
  { path: '/agent/onboarding', label: 'Onboarding' },
  { path: '/agent/companies', label: 'Companies' },
];

export const agentClientsFilters = [
  { path: '/agent/clients/all', label: 'All', searchText: '' },
  // { path: '/agent/clients/deactivated', label: 'Deactivated', searchText: '' },
];

export const  agentOnboaringFilters = [
  {
    path: '/agent/onboarding/outstanding-details',
    label: 'Outstanding details',
    searchText: ''
  },
  {
    path: '/agent/onboarding/booking-pending',
    label: 'Booking pending',
    searchText: ''
  },
  { path: '/agent/onboarding/scheduled', label: 'Scheduled', searchText: '' },
];


export const agentCompaniesFilters = [
  { path: '/agent/companies/all', label: 'All', searchText: '' },
];