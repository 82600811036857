import { useAuth } from 'context/useAuth';
import { Navigate, Outlet } from 'react-router-dom';

export default function ProtectedRoute() {
  const { authorisedUser } = useAuth();

  if (!authorisedUser) {
    return <Navigate to='/' />;
  }
  return <Outlet />;
}
