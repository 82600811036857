import axios from 'axios';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { useEffect, useState } from 'react';

export interface CompanyInterface {
  id: number;
  name: string;
  companyUsers: {
    id: number;
    userId: number;
    companyId: number;
  }[];
  vehicles: {
    id: number;
    companyId: number;
    make: string;
    model: string;
    year: number;
  }[];
}

export default function useGetCompanies(filters?: string) {
  const { authorisedUser } = useAuth();
  const settings = useAppSettings();

  const [companies, setCompanies] = useState<CompanyInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function getCompanies() {
      if (settings?.baseURL) {
        let URL = `${settings.baseURL}/Companies`;

        if (filters) {
          URL = `${settings.baseURL}/Companies?$filter=${filters}`;
        }

        setLoading(true);

        try {
          const response = await axios.get(URL, {
            headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
          });

          if (response?.status === 200) {
            setCompanies(response.data.items);
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setError(error.message);
          }
        } finally {
          setLoading(false);
          sortCompanies('');
        }
      }
    }
    getCompanies();
  }, [authorisedUser.accessToken, filters, settings.baseURL]);

  function sortCompanies(label: string) {
    switch (label) {
      case 'A - Z':
        setCompanies((prevState) => {
          return prevState.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
        });
        break;
      case 'Z - A':
        setCompanies((prevState) => {
          return prevState.sort((a, b) =>
            b.name.localeCompare(a.name)
          );
        });
        break;
      default:
        setCompanies((prevState) => {
          return prevState.sort((a, b) =>
            a.id - b.id
          );
        });
    }
  }

  return {
    companies,
    loading,
    error,
    sortCompanies,
  };
}