import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/Button/Button';
import ClientCard from 'components/ClientCard/ClientCard';
import ClientList from 'components/ClientList/ClientList';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import useGetVehicles from 'functions/hooks/useGetVehicles';
import FilterNavigation from 'components/FilterNavigation/FilterNavigation';
import { agentOnboaringFilters } from 'pages/Agent/navigation';
import { defaultSortOptions } from 'pages/Agent/menuLinks';

export default function Scheduled() {
  const settings = useAppSettings();
  const { authorisedUser } = useAuth();
  const [ searchTextLowerCase, setSearchTextLowerCase] = useState('');
  const { vehicles, loadingVehicles, sortVehicles } = useGetVehicles(
    "onBoardingStatus eq 'Scheduled'"
  );

  const [currentlyResending, setCurrentlyResending] = useState(0);

  async function handleResendPayment(id: number) {
    setCurrentlyResending(id);

    if (settings.baseURL) {
      const URL = `${settings.baseURL}/Subscriptions/${id}/FirstPaymentRetry`;

      try {
        const response = await axios.post(
          URL,
          {},
          { headers: { Authorization: `Bearer ${authorisedUser.accessToken}` } }
        );

        if (response?.status === 200) {
          setCurrentlyResending(0);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log(error);
        }
      } finally {
        setCurrentlyResending(0);
      }
    }
  }

  return (
    <>
      <FilterNavigation
        filters={agentOnboaringFilters}
        onSortChange={sortVehicles}
        sortOptions={defaultSortOptions}
        onSearchChange={searchText => setSearchTextLowerCase(searchText?.toLocaleLowerCase()?.trim())}
      />
      <ClientList>
        {loadingVehicles && (
          <div className='client-list__loader'>
            <CircularProgress />
          </div>
        )}
        {!loadingVehicles && vehicles.length === 0 && (
          <p>There are no installations scheduled</p>
        )}
        {!loadingVehicles && vehicles.length !== 0 && (
          <>
            {vehicles
            .filter((vehicle) => {
              return !searchTextLowerCase ||
              vehicle.userFirstName?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1 ||
              vehicle.userLastName?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1 ||
              vehicle.makeVariant?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1 ||
              vehicle.registrationNumber?.toLocaleLowerCase()?.indexOf(searchTextLowerCase) > -1
            })
            .map((item) => (
              <ClientCard
                key={item.id}
                clientData={{
                  active: true,
                  email: item.userEmail,
                  firstName: item.userFirstName,
                  id: item.userId,
                  lastName: item.userLastName,
                  registrationNumber: item.registrationNumber,
                  makeVariant: item.makeVariant,
                }}
                expandable={false}
                actionButtons={
                  <div className='client-card__button-grid'>
                    <Link
                      to={`/agent/onboarding/client/${item.id}/installation`}
                    >
                      <Button label='Reschedule' small outline />
                    </Link>
                    {!!item.subscriptionId &&
                      !item.subscriptionDepositAmountPaid && (
                        <Button
                          aria-label='resend'
                          disabled={currentlyResending === item.subscriptionId}
                          label={
                            currentlyResending === item.subscriptionId
                              ? 'Resending payment'
                              : 'Resend payment'
                          }
                          onClick={() =>
                            handleResendPayment(item.subscriptionId)
                          }
                          secondary
                          small
                        />
                      )}
                    {!item.subscriptionId &&
                      !item.subscriptionDepositAmountPaid && (
                        <Link
                          to={`/agent/onboarding/client/${item.id}/payment`}
                        >
                          <Button label='Add subscription' small />
                        </Link>
                      )}
                  </div>
                }
              />
            ))}
          </>
        )}
      </ClientList>
    </>
  );
}
