import axios from 'axios';
import React, { useState } from 'react';

import Button from 'components/Button/Button';
import { FormInput } from 'components/FormInput/FormInput';
import FormRow from 'components/FormRow/FormRow';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { ClientInterface } from 'functions/hooks/useGetUsers';
import { REGEX, validateInput } from 'functions/validationUtils';
import { FormErrors } from 'types/signUpForm';

import { handleOnChange } from './AddClientForm';

import 'layouts/FormLayout/FormLayout.scss';

interface EditClientProps {
  userDetails: ClientInterface;
}

export default function EditClientForm({ userDetails }: EditClientProps) {
  const settings = useAppSettings();
  const { authorisedUser } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSouthAfricanId = REGEX.IdNumberIsSouthAfrican.test(
    userDetails.idNumber
  );
  const [clientDetails, setClientDetails] = useState<ClientInterface>({
    createdOnDateTime: userDetails.createdOnDateTime,
    email: userDetails.email,
    firstName: userDetails.firstName,
    id: userDetails.id,
    idNumber: userDetails.idNumber,
    idNumberIsSouthAfrican: isSouthAfricanId,
    lastName: userDetails.lastName,
    phoneCode: userDetails.phoneCode,
    phoneNumber: userDetails.phoneNumber,
    tamperActive: userDetails.tamperActive,
  });

  const [clientDetailsError, setClientDetailsError] = useState<FormErrors>({
    Email: [],
    PhoneNumber: [],
    FirstName: [],
    IdNumber: [],
    LastName: [],
  });

  function handleInputValidation(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setClientDetailsError((prevState) => ({
      ...prevState,
      [id]: validateInput(id, value, isSouthAfricanId),
    }));
  }

  async function handleSubmit() {
    const URL = `${settings.baseURL}/Users/${userDetails.id}`;
    setIsSubmitting(true);

    try {
      const response = await axios.put(URL, clientDetails, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });

      if (response?.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setClientDetailsError(error.response?.data.errors);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <form className='form__edit'>
        <FormRow>
          <FormInput
            id='firstName'
            label={''}
            disabled
            value={clientDetails.firstName}
          />
        </FormRow>
        <FormRow>
          <FormInput
            id='lastName'
            label={''}
            disabled
            value={clientDetails.lastName}
          />
        </FormRow>
        <FormRow>
          <FormInput
            id='email'
            label='Email address'
            value={clientDetails.email}
            onChange={(e) =>
              handleOnChange(e, setClientDetails, setClientDetailsError)
            }
            errorMessage={clientDetailsError.Email}
            onBlur={handleInputValidation}
          />
        </FormRow>
        <FormRow>
          <FormInput
            type='tel'
            id='phoneNumber'
            value={clientDetails.phoneNumber}
            label='Cellphone number'
            onChange={(e) =>
              handleOnChange(e, setClientDetails, setClientDetailsError)
            }
            errorMessage={clientDetailsError.PhoneNumber}
            onBlur={handleInputValidation}
          />
        </FormRow>
        <FormRow>
          <FormInput
            type='text'
            id='idNumber'
            label={''}
            disabled
            value={clientDetails.idNumber}
          />
        </FormRow>
      </form>

      <div className='form-actions'>
        <Button
          label={isSubmitting ? 'Submitting' : 'Save'}
          small
          onClick={handleSubmit}
          disabled={
            !clientDetails.phoneNumber || !clientDetails.email || isSubmitting
          }
        />
      </div>
    </>
  );
}
