import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import CompanyInfoCard from 'components/CompanyInfoCard/CompanyInfoCard';
import useGetCompanyUsers from 'functions/hooks/useGetCompanyUsers';
import useGetUsers from 'functions/hooks/useGetUsers'; // Assuming this hook exists
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';

interface LinkUserProps {
  viewOnly?: boolean;
  companyName?: string;
}

export default function ViewAndLinkCompanyUsers({ viewOnly = false, companyName = "" }: LinkUserProps) {
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<string>('Company-Admin');
  const [linking, setLinking] = useState<boolean>(false);
  const [removing, setRemoving] = useState<{ [key: string]: boolean }>({});
  const [deletingCompany, setDeletingCompany] = useState<boolean>(false);
  const settings = useAppSettings();
  const { authorisedUser } = useAuth();

  useEffect(() => {
    document.title = 'Link User | Titan Secure';
  }, []);

  const { companyId } = useParams<{ companyId: string }>();
  const { users: companyUsers, loading: companyUsersLoading } = useGetCompanyUsers(Number(companyId));
  const { users: allUsers, loading: allUsersLoading } = useGetUsers();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleLinkUser = async () => {
    setLinking(true);
    try {
      const response = await fetch(`${settings.baseURL}/Companies/${companyId}/AddCompanyUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authorisedUser.accessToken}`
        },
        body: JSON.stringify({ userId: selectedUser, companyId, role: selectedRole }),
      });

      if (response.ok) {
        // Refresh page
        window.location.reload();
      }
      else {
        throw new Error('Failed to link user');
      }

      // Optionally, refresh the list of company users or show a success message
      console.log(`User ${selectedUser} linked to company ${companyId}`);
    } catch (error) {
      console.error('Error linking user:', error);
    } finally {
      setLinking(false);
    }
  };

  const handleRemoveUser = async (userId: number) => {
    setRemoving((prev) => ({ ...prev, [userId]: true }));
    try {
      const response = await fetch(`${settings.baseURL}/Companies/${companyId}/CompanyUsers/${userId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authorisedUser.accessToken}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to remove user');
      }

      // Refresh page
      window.location.reload();
    } catch (error) {
      console.error('Error removing user:', error);
    } finally {
      setRemoving((prev) => ({ ...prev, [userId]: false }));
    }
  };

  const renderCompanyUsers = () => {
    if (companyUsers.length === 0) {
      return <p style={{ textAlign: 'center', fontStyle: "italic", color: "orange", }}>No users linked to this company yet.</p>;
    }

    return companyUsers.map((user) => (
      <div key={user.id}>
        <CompanyInfoCard
          title={`${user.firstName} ${user.lastName}`}
          clientDetails={user}
        />
        <Button
          variant="contained"
          color="error"
          onClick={() => handleRemoveUser(user.id)}
          disabled={removing[user.id]}
          style = {{
            marginLeft: "10px",
            marginBottom: '20px',
          }}
        >
          {removing[user.id] ? 'Removing...' : 'Remove from company'}
        </Button>
      </div>
    ));
  };

  const renderLinkUsers = () => {
    return <>
      <h4 style={{ textAlign: 'center', margin: '20px 0' }}>Link New User</h4>
      {
        allUsersLoading ? (
          <div className='account__loader'>
            <CircularProgress />
          </div>
        ) : (
          <div style={{ textAlign: 'center', margin: '40px 0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Select
              value={selectedUser}
              onChange={(e) => setSelectedUser(e.target.value as string)}
              displayEmpty
              style={{ minWidth: 200 }}
            >
              <MenuItem value="" disabled>Select a user</MenuItem>
              {allUsers.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.firstName} {user.lastName}
                </MenuItem>
              ))}
            </Select>
            <Select
              value={selectedRole}
              onChange={(e) => setSelectedRole(e.target.value as string)}
              displayEmpty
              style={{ minWidth: 200, marginLeft: '10px' }}
            >
              <MenuItem value="Company-Admin">Company-Admin</MenuItem>
              <MenuItem value="Account-Holder">Account-Holder</MenuItem>
            </Select>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLinkUser}
              disabled={!selectedUser || linking}
              style={{ marginLeft: '10px' }}
            >
              {linking ? 'Linking...' : 'Link'}
            </Button>
          </div>
        )
      }
    </>
  }

  const handleDeleteCompany = async () => {
    setDeletingCompany(true);
    try {
      const response = await fetch(`${settings.baseURL}/Companies/${companyId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authorisedUser.accessToken}`
        },
      });

      if (!response.ok) {
        throw new Error('Failed to remove company');
      }

      // Back to companies
      navigate('/agent/companies/all');
    } catch (error) {
      console.error('Error removing company:', error);
    } finally {
      setDeletingCompany(false);
    }
  };

  return (
    <FormLayout>
      <FormLayout.Header>
        <FormLayout.HeaderBack onClose={handleGoBack} />
        <p>{viewOnly ? "View Company" : "Link Company Users"}</p>
      </FormLayout.Header>

      <FormLayout.Main>
        <div style = {{
          overflow: viewOnly ? 'hidden' : ''
        }}>
          
          {
            viewOnly && <div>
              <h2 style={{ textAlign: 'center', }}>{companyName}</h2>

              <div style = {{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleDeleteCompany()}
                  disabled={deletingCompany}
                  style = {{
                    marginLeft: "10px",
                    marginBottom: '20px',
                  }}
                >
                  {deletingCompany ? 'Deleting...' : 'Delete Company'}
                </Button>
              </div>
            </div>
          }

          {
            !viewOnly && renderLinkUsers()
          }
            
          <h4 style={{ textAlign: 'center', margin: '20px 0' }}>Existing Users</h4>
          {companyUsersLoading && (
            <div className='account__loader'>
              <CircularProgress />
            </div>
          )}
          {!companyUsersLoading && renderCompanyUsers()}
        </div>
      </FormLayout.Main>
    </FormLayout>
  );
}