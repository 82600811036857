import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import './Menu.scss';

export interface MenuLink {
  path: string;
  label: string;
}

interface MenuLinkProps {
  links: MenuLink[];
  buttonComponent: React.ReactNode;
  onMenuClick?: (label: string) => void;
}

export default function DropdownMenu({
  links,
  buttonComponent,
  onMenuClick,
  selectedLabel,
}: MenuLinkProps & { selectedLabel?: string }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (label: string) => {
    if (onMenuClick) {
      onMenuClick(label);
    }
    handleClose();
  };

  return (
    <div aria-label='nav dropdown' id = "mainSide">
      {React.cloneElement(buttonComponent as React.ReactElement, {
        'aria-controls': open ? 'basic-menu' : undefined,
        'aria-haspopup': 'true',
        'aria-expanded': open ? 'true' : undefined,
        onClick: handleClick,
        className: 'menu-button',
      })}
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        aria-label='menu dropdown'
        className='menu'
      >
        {links.map((link: MenuLink) => (
          <NavLink
            key={link.label}
            to={link.path}
            className={`menu-link ${
              selectedLabel === link.label ? 'menu-link-active' : ''
            }`}
          >
            <MenuItem
              key={link.label}
              onClick={() => handleMenuItemClick(link.label)}
              aria-label='menu-item'
            >
              {link.label}
            </MenuItem>
          </NavLink>
        ))}
      </Menu>
    </div>
  );
}
