import axios from 'axios';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

import Button from 'components/Button/Button';
import { FormInput } from 'components/FormInput/FormInput';
import FormRow from 'components/FormRow/FormRow';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { trimInputValue } from 'functions/trimInputValue';
import { REGEX, validateInput } from 'functions/validationUtils';
import { DriverInterface } from 'types/DriverInterface';
import { FormErrors } from 'types/signUpForm';

import 'layouts/FormLayout/FormLayout.scss';

interface EditDriverProps {
  userDetails: DriverInterface;
}

export default function EditDriverForm({ userDetails }: EditDriverProps) {
  const settings = useAppSettings();
  const { authorisedUser } = useAuth();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [driverDetails, setDriverDetails] = useState<DriverInterface>({
    email: userDetails.email,
    firstName: userDetails.firstName,
    id: userDetails.id,
    idNumber: userDetails.idNumber,
    idNumberIsSouthAfrican: userDetails.idNumberIsSouthAfrican,
    lastName: userDetails.lastName,
    phoneCode: userDetails.phoneCode,
    phoneNumber: userDetails.phoneNumber,
    vehicleId: userDetails.vehicleId,
  });

  const [driverDetailsError, setDriverDetailsError] = useState<FormErrors>({
    Email: [],
    PhoneNumber: [],
    FirstName: [],
    IdNumber: [],
    LastName: [],
  });

  function handleOnChange(
    e: ChangeEvent<HTMLInputElement>,
    setClientDetails: Dispatch<SetStateAction<DriverInterface>>
  ) {
    const { id, value, type, checked } = e.target;
    const inputValue = trimInputValue(id, value);

    if (id === 'phoneNumber') {
      if (REGEX.Numbers.test(inputValue) || !inputValue) {
        setClientDetails((prevState) => ({
          ...prevState,
          [id]: inputValue,
        }));
      }
    } else {
      setClientDetails((prevState) => ({
        ...prevState,
        [id]: type === 'checkbox' ? checked : inputValue,
      }));
    }
  }

  function handleInputValidation(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setDriverDetailsError((prevState) => ({
      ...prevState,
      [id]: validateInput(id, value, driverDetails.idNumberIsSouthAfrican),
    }));
  }

  async function handleSubmit() {
    const URL = `${settings.baseURL}/Drivers/${userDetails.id}`;
    setIsSubmitting(true);

    try {
      const response = await axios.put(URL, driverDetails, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
      });

      if (response?.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setDriverDetailsError(error.response?.data.errors);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <>
      <form className='form__edit'>
        <FormRow>
          <FormInput
            id='firstName'
            label={''}
            disabled
            value={driverDetails.firstName}
          />
        </FormRow>
        <FormRow>
          <FormInput
            id='lastName'
            label={''}
            disabled
            value={driverDetails.lastName}
          />
        </FormRow>
        <FormRow>
          <FormInput
            id='email'
            label='Email address'
            value={driverDetails.email}
            onChange={(e) => handleOnChange(e, setDriverDetails)}
            errorMessage={driverDetailsError.Email}
            onBlur={handleInputValidation}
          />
        </FormRow>
        <FormRow>
          <FormInput
            type='tel'
            id='phoneNumber'
            value={driverDetails.phoneNumber}
            label='Cellphone number'
            onChange={(e) => handleOnChange(e, setDriverDetails)}
            errorMessage={driverDetailsError.PhoneNumber}
            onBlur={handleInputValidation}
          />
        </FormRow>
        <FormRow>
          <FormInput
            type='text'
            id='IdNumber'
            label={''}
            disabled
            value={driverDetails.idNumber}
          />
        </FormRow>
      </form>

      <div className='form-actions'>
        <Button
          label={isSubmitting ? 'Submitting' : 'Save'}
          small
          onClick={handleSubmit}
          disabled={
            !driverDetails.phoneNumber || !driverDetails.email || isSubmitting
          }
        />
      </div>
    </>
  );
}
