import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import InfoCard from 'components/InfoCard/InfoCard';
import useAxiosGet from 'functions/hooks/useAxiosGet';
import useGetUsers from 'functions/hooks/useGetUsers';
import useGetVehicles from 'functions/hooks/useGetVehicles';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { SubscriptionPlan } from 'types/SubscriptionPlan';
import Button from 'components/Button/Button';
import { useAppSettings } from 'context/appsettingsContext';
import axios from 'axios';
import { useAuth } from 'context/useAuth';

export default function ViewAccount() {

  const settings = useAppSettings();
  const { clientId } = useParams();
  const { data: subscriptionPlans } = useAxiosGet('SubscriptionPlans');
  const { users: user, loading } = useGetUsers(
    `Roles/Any(r:r eq 'Account-Holder') and id eq ${clientId}`
  );
  const { authorisedUser } = useAuth();
  const { vehicles, loadingVehicles } = useGetVehicles(`userId eq ${clientId}`);
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleResendPasswordLink = async () => {
    try {
      await axios.post(
        `${settings.baseURL}/Users/ResendPasswordCreationLink`,
        {
          userId: user[0]?.id,
        },
        { headers: { Authorization: `Bearer ${authorisedUser.accessToken}` } }
      );

      alert('Password creation link resent successfully.');
    } catch (error) {
      console.error('Error resending password creation link:', error);
      alert('Failed to resend password creation link.');
    }
  };

  useEffect(() => {
    document.title = 'View Account | Titan Secure';
  });

  return (
    <FormLayout>
      
      <FormLayout.Header>
        <FormLayout.HeaderBack onClose={handleGoBack} />
        <p>View account</p>
      </FormLayout.Header>

      <FormLayout.Main>
        {loading && (
          <div className='account__loader'>
            <CircularProgress />
          </div>
        )}
        {!loading && user.length !== 0 && (
          <>
          <InfoCard
            key={user[0]?.id}
            title={`${user[0]?.firstName} ${user[0]?.lastName}`}
            clientDetails={user[0]}
          />
          <div style = {{
            justifyContent: 'center',
            display: 'flex',
          }}>
            <Button
              onClick={handleResendPasswordLink}
              label = " Resend Create Password Link"
            />
          </div>
        </>
        )}

        {loadingVehicles && (
          <div className='account__loader'>
            <CircularProgress />
          </div>
        )}

        {!loadingVehicles &&
          vehicles.map((vehicle) => (
            <div key={vehicle?.id}>
              {vehicle.drivers.map((driver) => (
                <InfoCard
                  key={driver?.id}
                  title={driver?.firstName + ' ' + driver?.lastName}
                  driverDetails={driver}
                />
              ))}
              {subscriptionPlans.map((plan: SubscriptionPlan) =>
                plan.id === vehicle.subscriptionPlanId ? (
                  <InfoCard
                    key={plan?.id}
                    subtitle={plan.displayName}
                    field={plan.description}
                  />
                ) : null
              )}
              <InfoCard
                key={vehicle?.id}
                title={vehicle?.registrationNumber}
                vehicleDetails={vehicle}
              />
            </div>
          ))}
      </FormLayout.Main>

      {/* <FormLayout.Footer>
        <div className='flex gap-1 justify-content-center'>
          <Button label='View account history' outline fullWidth nowrap />
        </div>
      </FormLayout.Footer> */}
    </FormLayout>
  );
}
