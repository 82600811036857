import axios from 'axios';
import { useAuth } from 'context/useAuth';
import { useAppSettings } from 'context/appsettingsContext';
import { useEffect, useState } from 'react';

export default function useAxiosGet(endpoint: string) {
  const { authorisedUser } = useAuth();
  const settings = useAppSettings();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function getData() {
      if (settings?.baseURL) {
        const URL = `${settings?.baseURL}/${endpoint}`;

        setLoading(true);

        try {
          const response = await axios.get(URL, {
            headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
          });

          if (response?.status === 200) {
            setData(response.data.items);
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setError(error.message);
          }
        } finally {
          setLoading(false);
        }
      }
    }
    getData();
  }, [authorisedUser.accessToken, endpoint, settings?.baseURL]);

  return {
    data,
    loading,
    error,
  };
}
