import axios from 'axios';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { useEffect, useState } from 'react';

export interface ClientInterface {
  email: string;
  firstName: string;
  id: number;
  idNumber: string;
  lastName: string;
  phoneCode: string;
  phoneNumber: string;
  totalNumberOfVehicles?: number;
  idNumberIsSouthAfrican?: boolean;
  createdOnDateTime: string;
  tamperActive: boolean;
}

export default function useGetCompanyUsers(companyId: number, filters?: string, orderBy?: string, pageSize?: number, pageIndex?: number) {
  const { authorisedUser } = useAuth();
  const settings = useAppSettings();

  const [users, setUsers] = useState<ClientInterface[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function getUsers() {
      if (settings?.baseURL) {
        let URL = `${settings.baseURL}/Companies/${companyId}/CompanyUsers`;

        const params = new URLSearchParams();
        if (filters) params.append('$filter', filters);
        if (orderBy) params.append('$orderby', orderBy);
        if (pageSize) params.append('$pagesize', pageSize.toString());
        if (pageIndex) params.append('$pageindex', pageIndex.toString());

        if (params.toString()) {
          URL += `?${params.toString()}`;
        }

        setLoading(true);

        console.log('URL', URL);

        try {
          const response = await axios.get(URL, {
            headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
          });

          if (response?.status === 200) {
            setUsers(response.data.items);
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            setError(error.message);
          }
        } finally {
          setLoading(false);
          sortUsers('');
        }
      }
    }
    getUsers();
  }, [authorisedUser.accessToken, companyId, filters, orderBy, pageSize, pageIndex, settings.baseURL]);

  function sortUsers(label: string) {
    switch (label) {
      case 'A - Z':
        setUsers((prevState) => {
          return prevState.sort((a, b) =>
            a.firstName.localeCompare(b.firstName)
          );
        });
        break;
      case 'Z - A':
        setUsers((prevState) => {
          return prevState.sort((a, b) =>
            b.firstName.localeCompare(a.firstName)
          );
        });
        break;
      case 'Active alerts':
        setUsers((prevState) => {
          return prevState.sort((a, b) =>
            a.tamperActive < b.tamperActive ? 1 : -1
          );
        });
        break;
      default:
        setUsers((prevState) => {
          return prevState.sort((a, b) =>
            b.createdOnDateTime.localeCompare(a.createdOnDateTime)
          );
        });
    }
  }

  return {
    users,
    loading,
    error,
    sortUsers,
  };
}