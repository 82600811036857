import { ReactComponent as MenuIcon } from 'assets/icons/menu.svg';

import '../Menu/Menu.scss';

interface MenuIconButtonProps {
  'aria-expanded'?: boolean | undefined;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void | undefined;
}

export default function MenuIconButton(props: MenuIconButtonProps) {
  const { 'aria-expanded': ariaExpanded, onClick } = props;

  return (
    <button
      id='basic-button'
      className='menu-button'
      aria-label='menu'
      aria-expanded={ariaExpanded}
      onClick={onClick}
    >
      <MenuIcon title='menu icon' />
    </button>
  );
}
