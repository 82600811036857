import React from 'react';
import './CompanyList.scss';

interface CompanyListProps {
  children: React.ReactNode;
}

export default function CompanyList({ children }: CompanyListProps) {
  return (
    <ul className='company-list' aria-label='company list'>
      {children}
    </ul>
  );
}
