import useGetUsers from 'functions/hooks/useGetUsers';
import { trimInputValue } from 'functions/trimInputValue';
import { REGEX, validateInput } from 'functions/validationUtils';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormErrors } from 'types/signUpForm';
import { ClientDetailType } from './AddClientForm';
import AddDriverForm from './AddDriverForm';
import AddVehicleForm from './AddVehicleForm';

const initialDetails: ClientDetailType = {
  Email: '',
  FirstName: '',
  IdNumber: '',
  IdNumberIsSouthAfrican: true,
  LastName: '',
  PhoneCode: '27',
  PhoneNumber: '',
  acceptedTermsAndConditionsAndPolicy: true,
  acceptedToAllowInformationToBeUsedToContact: true,
};

const initialErrors: FormErrors = {
  Email: [],
  FirstName: [],
  IdNumber: [],
  LastName: [],
  PhoneNumber: [],
};

export type DriverOptionType = 'current-account' | 'new-driver';

export default function AddDriverAndVehicle() {
  const { clientId } = useParams();
  const { users: user } = useGetUsers(
    `Roles/Any(r:r eq 'Account-Holder') and id eq ${clientId}`
  );

  const [currentStep, setCurrentStep] = useState('driver-details');
  const [clientDetails, setClientDetails] = useState(initialDetails);
  const [clientDetailsError, setClientDetailsError] = useState(initialErrors);
  const [driverOption, setDriverOption] = useState('new-driver');

  function handleDriverInputValidation(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    setClientDetailsError((prevState) => ({
      ...prevState,
      [id]: validateInput(id, value, clientDetails.IdNumberIsSouthAfrican),
    }));
  }

  function handleDriverInputOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value, type, checked } = e.target;

    const inputValue = trimInputValue(id, value);

    if (id === 'PhoneNumber') {
      if (REGEX.Numbers.test(inputValue) || !inputValue) {
        setClientDetails((prevState) => ({ ...prevState, [id]: inputValue }));
      }
    } else {
      setClientDetails((prevState) => ({
        ...prevState,
        [id]: type === 'checkbox' ? checked : inputValue,
      }));
    }

    if (id === 'IdNumberIsSouthAfrican') {
      handleDriverIdReset();
    }
  }

  function handleDriverIdReset() {
    setClientDetails((prevState) => ({
      ...prevState,
      IdNumber: '',
    }));

    setClientDetailsError((prevState) => ({
      ...prevState,
      IdNumber: [],
    }));
  }

  function handleDriverSelection(option: string) {
    setDriverOption(option);
    setClientDetailsError(initialErrors);

    if (option === 'current-account') {
      const isSouthAfricanId = REGEX.IdNumberIsSouthAfrican.test(
        user[0].idNumber
      );

      setClientDetails((prevState) => ({
        ...prevState,
        ...{
          Email: user[0].email,
          FirstName: user[0].firstName,
          LastName: user[0].lastName,
          IdNumber: user[0].idNumber,
          IdNumberIsSouthAfrican: isSouthAfricanId,
          PhoneCode: '27',
          PhoneNumber: user[0].phoneNumber,
          acceptedTermsAndConditionsAndPolicy: true,
          acceptedToAllowInformationToBeUsedToContact: true,
        },
      }));
    } else {
      setClientDetails(initialDetails);
    }
  }

  function handleFormChange(step: string) {
    setCurrentStep(step);
  }

  return (
    <>
      {currentStep === 'driver-details' && (
        <AddDriverForm
          formData={clientDetails}
          formErrors={clientDetailsError}
          handleValidation={handleDriverInputValidation}
          handleChange={handleDriverInputOnChange}
          handleFormChange={handleFormChange}
          driverOptions={driverOption}
          handleDriverOptions={handleDriverSelection}
        />
      )}
      {currentStep === 'vehicle-details' && (
        <AddVehicleForm
          clientDetails={clientDetails}
          handleFormChange={handleFormChange}
        />
      )}
    </>
  );
}
