import { ReactComponent as AlertIcon } from 'assets/icons/alert.svg';
import { ReactComponent as CircleIcon } from 'assets/icons/circle.svg';
import { ReactComponent as LockedIcon } from 'assets/icons/locked.svg';
import { ReactComponent as RightArrowIcon } from 'assets/icons/right-arrow.svg';
import { ReactComponent as UnlockedIcon } from 'assets/icons/unlocked.svg';

import './Badge.scss';

export type BadgeTypes =
  | 'default'
  | 'alert'
  | 'locked'
  | 'low-car-battery'
  | 'low-device-battery'
  | 'no-device'
  | 'unlocked';

interface BadgeProps {
  state?: BadgeTypes;
}

interface StatusBadge {
  label: string;
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

interface StatusBadges {
  default: StatusBadge;
  alert: StatusBadge;
  locked: StatusBadge;
  unlocked: StatusBadge;
  'low-car-battery': StatusBadge;
  'low-device-battery': StatusBadge;
  'no-device': StatusBadge;
}

export default function Badge({ state = 'default' }: BadgeProps) {
  const badgeStyle: StatusBadges = {
    default: {
      label: 'Badge',
    },
    alert: {
      label: 'Alert',
      icon: <AlertIcon title='alert icon' />,
    },
    locked: {
      label: 'Locked!',
      icon: <LockedIcon title='locked icon' />,
    },
    unlocked: {
      label: 'Unlocked',
      icon: <UnlockedIcon title='unlocked icon' />,
    },
    'low-car-battery': {
      label: 'Low car battery',
    },
    'low-device-battery': {
      label: 'Low device battery',
    },
    'no-device': {
      label: 'No device added',
      icon: <CircleIcon title='circle icon' />,
      rightIcon: <RightArrowIcon title='right arrow icon' />,
    },
  };

  return (
    <div className={`badge badge--${state}`}>
      {badgeStyle[state].icon}
      {badgeStyle[state].label}
      {badgeStyle[state].rightIcon}
    </div>
  );
}
