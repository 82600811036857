import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { useAppSettings } from 'context/appsettingsContext';
import { useAuth } from 'context/useAuth';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VehicleInterface } from 'types/VehicleInterface';
import axios from 'axios';
import BadgeCard from 'components/BadgeCard/BadgeCard';
import Button from 'components/Button/Button';
import CircularProgress from '@mui/material/CircularProgress';
import useAxiosGet from 'functions/hooks/useAxiosGet';

export default function VehicleHistory() {
  useEffect(() => {
    document.title = 'Vehicle History | Titan Secure';
  });

  const settings = useAppSettings();
  const { authorisedUser } = useAuth();
  const { clientId, vehicleId } = useParams();
  const { data: vehicleHistory, loading: loadingHistory } = useAxiosGet(
    `Vehicles/${vehicleId}/History?$orderby=dateTime desc`
  );
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };

  async function handleHistoryDownload() {
    const URL = `${settings.baseURL}/Vehicles/${vehicleId}/History/Csv`;

    try {
      const response = await axios.get(URL, {
        headers: { Authorization: `Bearer ${authorisedUser.accessToken}` },
        responseType: 'blob',
      });
      if (response?.status === 200) {
        const href = window.URL.createObjectURL(response.data);
        const anchorElement = document.createElement('a');
        anchorElement.href = href;
        anchorElement.download = `Vehicle-${vehicleId}-history.csv`;
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error);
      }
    }
  }

  return (
    <FormLayout>
      <FormLayout.Header>
        <FormLayout.HeaderBack
          onClose={handleGoBack}
        />
        <p>Vehicle history</p>
      </FormLayout.Header>

      <FormLayout.Main>
        {loadingHistory && (
          <div className='account__loader'>
            <CircularProgress />
          </div>
        )}
        {!loadingHistory &&
          vehicleHistory.length > 0 &&
          vehicleHistory.map((vehicle: VehicleInterface) => (
            <BadgeCard key={vehicle.id} details={vehicle} />
          ))}
        {!loadingHistory && vehicleHistory && vehicleHistory.length === 0 && (
          <p>No history</p>
        )}
      </FormLayout.Main>

      <FormLayout.Footer>
        <div className='flex gap-1 justify-content-center'>
          <Button
            label='Download history'
            outline
            fullWidth
            nowrap
            disabled={vehicleHistory.length === 0}
            onClick={handleHistoryDownload}
          />
        </div>
      </FormLayout.Footer>
    </FormLayout>
  );
}
