import './NotFound.scss';

export default function NotFound() {
  return (
    <div className='not-found'>
      <h1>404</h1>
      <p>Oops! It looks like the page you're looking for is lost.</p>
    </div>
  );
}
