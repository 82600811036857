import { FormInput } from 'components/FormInput/FormInput';
import { FormLayout } from 'layouts/FormLayout/FormLayout';
import { trimInputValue } from 'functions/trimInputValue';
import { useAppSettings } from 'context/appsettingsContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { validateInput } from 'functions/validationUtils';
import axios from 'axios';
import Button from 'components/Button/Button';
import FormRow from 'components/FormRow/FormRow';
import { Helmet } from 'react-helmet';

export default function ForgotPassword() {
  const settings = useAppSettings();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ Email: '' });
  const [formErrors, setFormErrors] = useState({ Email: [] });
  const [isSubmitting, setIsSubmitting] = useState(false);

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;
    const inputValue = trimInputValue(id, value);

    setFormData((prevState) => ({ ...prevState, [id]: inputValue }));
  }

  function handleInputValidation(e: React.ChangeEvent<HTMLInputElement>) {
    const { id, value } = e.target;

    setFormErrors((prevState) => ({
      ...prevState,
      [id]: validateInput(id, value, false),
    }));
  }

  async function handleSubmit() {
    const URL = `${settings.baseURL}/Auth/StartPasswordReset`;

    setIsSubmitting(true);

    try {
      const response = await axios.post(URL, formData);
      if (response?.status === 200) {
        navigate('/');
      }
    } catch (error) {
      console.log("Error:");
      console.log(error);
      if (axios.isAxiosError(error)) {
        setFormErrors(error.response?.data.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>Forgot password | Titan Secure</title>
      </Helmet>
      <FormLayout>
        <FormLayout.Header>
          <FormLayout.HeaderBack onClose={handleGoBack} iconType='back' />
          <p>Forgot password</p>
        </FormLayout.Header>
        <FormLayout.Main>
          <FormRow>
            <p>
              Enter the email address yor registered with. We’ll send you an
              email in order to let you create a new password.
            </p>
          </FormRow>
          <FormRow>
            <FormInput
              id='Email'
              label='Email address'
              onChange={handleInputChange}
              value={formData.Email}
              errorMessage={formErrors.Email}
              onBlur={handleInputValidation}
            />
          </FormRow>
        </FormLayout.Main>
        <FormLayout.Footer>
          <Button
            label={isSubmitting ? 'Submitting' : 'Reset password'}
            fullWidth
            disabled={
              formErrors.Email.length !== 0 || !formData.Email || isSubmitting
            }
            onClick={handleSubmit}
          />
        </FormLayout.Footer>
      </FormLayout>
    </>
  );
}
