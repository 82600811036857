import { ReactComponent as ArrowDownIcon } from 'assets/icons/down-arrow.svg';

import '../Button/Button.scss';

interface FilterButtonProps {
  'aria-expanded'?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  label: string;
}

export default function FilterButton(props: FilterButtonProps) {
  const { 'aria-expanded': ariaExpanded, onClick, label } = props;

  return (
    <button
      id='basic-button'
      data-testid='basic-menu'
      className='button button--secondary button--icon button--small'
      aria-expanded={ariaExpanded}
      onClick={onClick}
    >
      {label}
      <ArrowDownIcon title='menu icon' />
    </button>
  );
}
